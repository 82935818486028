import React from 'react';
import { Grid, Button, CircularProgress } from '@mui/material';
import { TextField } from 'components/presentationals/Fields';
import { RULES, NATIONALITY } from 'constants/index';
import { useFormHook } from 'hooks/useFormHook';
import SelectForm from 'components/presentationals/Fields/TextFieldSelect';

import { TUseFormHookProps } from 'types';
import { SubmitHandler } from 'react-hook-form';

const SearchWorker: React.FC<TSearchWorkerProps> = ({
  onSubmit,
  loadingService
}) => {
  const DEFAULT_VALUES = {
    cedula: '',
    nacionalidad: 'V'
  };
  const { control, handleSubmit, errors } = useFormHook(DEFAULT_VALUES);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <SelectForm
            name="nacionalidad"
            variant="standard"
            control={control}
            options={NATIONALITY}
            label="Nacionalidad"
            rules={RULES.requerido}
            error={Boolean(errors.nacionalidad)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="cedula"
            type="text"
            label="Cedula"
            variant="standard"
            control={control}
            rules={RULES.cedula}
            error={Boolean(errors.cedula)}
            errmsg={errors.cedula}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            type="submit"
            disabled={loadingService}>
            {loadingService ? <CircularProgress size={24} /> : 'Buscar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export type TSearchWorkerProps = {
  onSubmit: SubmitHandler<TUseFormHookProps>;
  loadingService: boolean;
};
export default SearchWorker;
