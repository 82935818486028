/* interface */
import { IRecibo, IUserConstanciaData } from 'interfaces/index';
import { getFormatDateString } from 'utils/helpers';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { IMAGE1 } from 'constants/images';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

const receipt: any = (props: IRecibo) => {
  const sourceData: any[] = props.reciboData;
  const {
    nacionalidad,
    cedula,
    primer_nombre,
    segundo_nombre,
    primer_apellido,
    segundo_apellido,
    cod_cargo,
    descripcion_cargo,
    cod_dependencia,
    nombre,
    tipo_personal,
    cuenta_nomina
  }: IUserConstanciaData = props.userData;

  var bodyData = [] as any;
  sourceData.forEach(function(row) {
    var dataRow = [] as any;
    dataRow.push({ text: row.cod_concepto, style: ['f8'] });
    dataRow.push({ text: row.descripcion, style: 'f8' });
    dataRow.push({
      text: row.asignaciones !== null ? `Bs. ${row.asignaciones}` : '',
      alignment: 'right',
      style: ['f8']
    });
    dataRow.push({
      text: row.deducciones !== null ? `Bs. ${row.deducciones}` : '',
      alignment: 'right',
      style: ['f8']
    });
    dataRow.push({
      text: row.total !== null ? `Bs. ${row.total}` : '',
      style: ['f8', 'center']
    });
    bodyData.push(dataRow);
  });
  bodyData.unshift([
    { text: 'Código', style: ['f8'] },
    { text: 'Concepto', style: ['f8'] },
    { text: 'Asignaciones', alignment: 'right', style: ['f8'] },
    { text: 'Deducciones', alignment: 'right', style: ['f8'] },
    { text: 'Total', alignment: 'center', style: ['f8'] }
  ]);

  const documentDefinition: any = {
    info: {
      title: `RECIBO DE PAGO: ${primer_apellido} ${primer_nombre}`,
      author: 'MINCYT'
    },
    pageSize: 'A4',
    pageOrientation: 'porttrait',
    styles: {
      f8: { fontSize: 7 },
      strong: { bold: true },
      center: { alignment: 'center' },
      firma: { fontSize: 8 }
    },
    content: [
      {
        image: IMAGE1,
        width: 250
      },
      {
        columns: [
          {
            text: '\n\n\n\n\n\n\n\n\n\n\n\nREPORTE DE RELACIÓN DE PAGO\n',
            style: 'strong'
          },
          {
            text: `\n\nFecha de impresión: ${getFormatDateString(
              new Date(),
              'dd/MM/yyyy'
            )}`,
            alignment: 'right'
          }
        ],
        style: 'f8'
      },
      {
        text: [
          {
            text: `${tipo_personal} RECIBO DEL: ${getFormatDateString(
              props.reciboData[0].fecha,
              'dd/MM/yyyy'
            )}\n\n`,
            style: 'strong'
          },
          { text: 'Cédula: ', style: 'strong' },
          `${nacionalidad}-${cedula}\n\n`,
          { text: 'Nombre: ', style: 'strong' },
          `${primer_apellido} ${segundo_apellido}, ${primer_nombre} ${segundo_nombre}\n\n`,
          { text: 'Cargo: ', style: 'strong' },
          `${cod_cargo} ${descripcion_cargo}\n\n`,
          { text: 'Ubicación: ', style: 'strong' },
          `${cod_dependencia} ${nombre}\n\n`,
          { text: 'Cuenta Nomina: ', style: 'strong' },
          `${cuenta_nomina}\n\n`
        ],
        style: 'f8'
      },
      {
        table: {
          widths: ['auto', 300, 'auto', 'auto', 'auto'],
          headerRows: 1,
          body: bodyData
        },
        layout: {
          fillColor: function(rowIndex: any, node: any, columnIndex: any) {
            return rowIndex % 2 === 0 ? '#CCCCCC' : null;
          }
        }
      }
    ]
  };

  pdfMake.createPdf(documentDefinition).open();
  //pdfMake.createPdf(documentDefinition).download('recibo.pdf');
};

export default receipt;
