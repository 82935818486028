/* @componentes */
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
/* @Mui */
import { makeStyles } from '@mui/styles';
import { Stack, Typography, Container, Paper } from '@mui/material';
import tema from '../../utils/theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
/* Hooks */
import { useVerifing } from './hooks/useVerifing';
import Valido from './components/Valido';

const myStyles = makeStyles({

  link: {
    alignItems: 'center',
    color: '#2166a1',
    fontWeight: 'bold',
    padding: '10px 0 0 10px'
  }
});

const VerifyDocument = () => {
  const classes = myStyles();
  const { verifyData } = useVerifing();
  const { status, data } = verifyData;
  return (
    <>
      <Helmet>
        <title>SAMINCYT - Validando documento</title>
      </Helmet>
      <Container maxWidth='md' sx={{ margin: 'auto' }}>

          <Link to='/validardocumento'>
            <Stack className={classes.link} direction='row'>
              <ArrowBackIcon /> Regresar
            </Stack>
          </Link>
          <Stack p={4} spacing={2}>
          <Typography  className="page-title-login" component='h1'>
              {`CONSTANCIA ${verifyData.status}`}
            </Typography>
            {status === 'VALIDA' ? (
              <Valido datos={data} />
            ) : status === 'INVALIDA' ? (
              <p></p>
            ) : (
              <p></p>
            )}
          </Stack>

      </Container>
    </>
  );
};

export default memo(VerifyDocument);
