import { SubmitHandler, FieldError } from 'react-hook-form';
/* redux */
import { login } from 'reducers/authSlice';
import { clearErrorsServer } from 'reducers/appSlice';

/* Types */
import { IPROPS } from 'interfaces/index';

/* Hooks */
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';

export const useLogin = (
  captcha: any,
  clearErrors: (name?: string | string[]) => void,
  setError: (name: string, error: FieldError) => void
) => {
  const dispatch = useAppDispatch();
  const { isLoading, isAuthenticated } = useAppSelector(
    (state) => state.authReducer
  );

  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    if (process.env.NODE_ENV === 'development') {
      await dispatch(login(data));
      await dispatch(clearErrorsServer());
      clearErrors();
    } else {
      if (captcha?.current.getValue()) {
        await dispatch(login(data));
        await dispatch(clearErrorsServer());
        clearErrors();
      } else {
        setError('captcha', {
          type: 'validado',
          message: 'No esta validado por el captcha'
        });
      }
    }
  };

  return {
    onSubmit,
    isLoading,
    isAuthenticated,
    clearErrors
  };
};
