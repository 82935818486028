import { useSnackbar, VariantType } from 'notistack';
import { useCallback } from 'react';

export const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Dispara una nueva alerta
   * @param message : string
   * @param variant : success | error | warning | info
   * @returns string
   */

  const openAlert = useCallback(
    (message: string, variant: VariantType) =>
      enqueueSnackbar(message, { variant }),
    [enqueueSnackbar]
  );

  return {
    openAlert
  };
};
