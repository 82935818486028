import { useEffect } from 'react';
import { SubmitHandler, FieldError } from 'react-hook-form';

/* Hooks */
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';

/* redux */
import {
  verifyWorker,
  clearVerified,
  registerWorker,
} from 'reducers/authSlice';
import { clearErrorsServer } from 'reducers/appSlice';

/* Types */
import { IPROPS } from 'interfaces/index';

export const useRegister = (
  captcha: any,
  reset: (
    values?: Record<string, any>,
    options?: Record<string, boolean>
  ) => void,
  getValues: (payload?: string | string[]) => Object,
  clearErrors: (name?: string | string[]) => void,
  setError: (name: string, error: FieldError) => void
) => {
  /* @State */
  const dispatch = useAppDispatch();

  const { isLoading, isVerified } = useAppSelector(
    (state) => state.authReducer
  );

  const onClear: () => void = () => {
    dispatch(clearVerified());
    reset();
  };
  const handleSetErrorPass: () => void = () => {
    setError('password2', {
      type: 'igualdad',
      message: 'contraseñas no son iguales',
    });
  };

  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    if (process.env.NODE_ENV === 'development') {
      if (!isVerified) {
        await dispatch(verifyWorker(data));
      } else {
        if (getValues('password') !== getValues('password2')) {
          handleSetErrorPass();
        } else {
          await dispatch(registerWorker(data));
          await clearErrors();
        }
      }
    } else {
      if (captcha?.current?.getValue()) {
        if (!isVerified) {
          await dispatch(verifyWorker(data));
        } else {
          if (getValues('password') !== getValues('password2')) {
            handleSetErrorPass();
          } else {
            await dispatch(registerWorker(data));
            await clearErrors();
          }
        }
      } else {
        setError('captcha', {
          type: 'validado',
          message: 'No esta validado por el captcha',
        });
      }
    }
    await dispatch(clearErrorsServer());
  };

  useEffect(() => {
    dispatch(clearVerified());
  }, [dispatch]);

  return {
    onSubmit,
    isLoading,
    isVerified,
    onClear,
  };
};
