import { SubmitHandler, FieldError } from 'react-hook-form';
import { recoverWorker } from 'reducers/authSlice';
import { useAppDispatch } from 'hooks/useRedux';
import { useNavigate } from 'react-router-dom';

/* Types */
import { IPROPS } from 'interfaces/index';
export const useRecover = (
  captcha: any,
  setError: (name: string, error: FieldError) => void
) => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    if (captcha?.current.getValue()) {
      await dispatch(recoverWorker(data));
      navigate('/iniciar-sesion');
    } else {
      setError('captcha', {
        type: 'validado',
        message: 'No esta validado por el captcha',
      });
    }
  };

  return {
    onSubmit,
  };
};
