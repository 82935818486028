import NotesIcon from '@mui/icons-material/Notes';
/* mui */
import { Button, CircularProgress, Stack } from '@mui/material';
import FooterWithBorder from 'components/FooterWithBorder';
/* Hooks */
import { useAppDispatch } from 'hooks/useRedux';
/* interface */
import { IUserData, IUserConstanciaData } from 'interfaces/user';
import { isUndefined } from 'lodash';
import randomstring from 'randomstring';
import { FC, memo, useMemo } from 'react';
/* reducer */
import { createConstancy } from 'reducers/userSlice';
/* Helper */
import { getTimeWork } from 'utils/helpers';
import ListGrid from 'components/ListGrid';

const DatosTrabajador: FC<IDatosTrabajador> = ({
  datos,
  loading,
  correo,
  generating,
  showButton,
  disabledButton
}) => {
  const dispatch = useAppDispatch();
  const { cedula }: IUserConstanciaData = datos;


  const dataList = useMemo(
    () => [
      {
        title: 'NOMBRES',
        content: `${datos?.primer_apellido} ${datos?.segundo_apellido}`
      },
      {
        title: 'APELLIDOS',
        content: `${datos?.primer_nombre} ${datos?.segundo_nombre}`
      },
      {
        title: 'CEDULA',
        content: `${datos?.nacionalidad}-${datos?.cedula}`
      },
      {
        title: 'CORREO INSTITUCIONAL',
        content: correo
      },
      {
        title: 'TIPO PERSONAL',
        content: datos?.tipo_personal
      },
      {
        title: 'SALARIO BÁSICO',
        content: datos?.sueldo_basico
          ? datos?.sueldo_basico + ' Bolivares'
          : '0 Bolivares'
      },
     /*  {
        title: 'CESTA TICKET',
        content: datos?.cesta_ticket + ' Bolivares'
      }, */
      {
        title: 'FECHA DE INGRESO',
        content: datos?.fecha_ingreso
      },
      {
        title: 'TIEMPO DE SERVICIO',
        content: !isUndefined(datos?.fecha_ingreso)
          ? getTimeWork(datos?.fecha_ingreso)
          : ''
      },
      {
        title: 'CÓDIGO DE CARGO',
        content: datos?.cod_cargo
      },
      {
        title: 'CARGO',
        content: datos?.descripcion_cargo
      },
      {
        title: 'CODIGO DE DEPENDENCIA',
        content: datos?.cod_dependencia
      },
      {
        title: 'DEPENDENCIA',
        content: datos?.nombre,
      }
    ],
    [datos, correo]
  );

  const handleClick = async () => {
    const codigo = randomstring.generate(7);
    await dispatch(createConstancy({ codigo, cedula }));
  };
  return (
    <>
      <ListGrid data={dataList} loading={loading} />

      {showButton && (
        <Stack mt={2} direction='row' justifyContent={'flex-end'}>
          <Button
            startIcon={!generating && <NotesIcon />}
            variant='contained'
            onClick={() => {
              handleClick();
            }}
            disabled={generating || disabledButton}>
            {generating ? <CircularProgress size={24} /> : 'Generar Constancia'}
          </Button>
        </Stack>
      )}

  
    </>
  );
};

/**
 * @method interface IDatosTrabajador
 */
export interface IDatosTrabajador extends IUserData {
  showButton?: boolean | undefined;
  disabledButton?: boolean;
}
export default memo(DatosTrabajador);
