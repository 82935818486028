import { ReactNode } from 'react'
import styles from './Backdrop.module.scss'

const Backdrop = ({ children }: TBackdropProps) => {
  return <div className={styles['Backdrop__container']}>{children}</div>
}

export type TBackdropProps = {
  children: ReactNode
}
export default Backdrop
