import React, { memo, useRef } from "react";
import { TextField } from "components/presentationals/Fields";

import ReCAPTCHA from "react-google-recaptcha";
/* @Mui */
import { Stack, Button, Alert } from "@mui/material";
/* Hooks */
import { useRecover } from "../hooks/useRecover";
import { useFormHook } from "hooks/useFormHook";
/* constants */
import { RULES } from "constants/index";

const RecoverForm: React.FC = () => {
  let captcha: any = useRef(null);
  const DEFAULT_VALUES = {
    username: "",
    captcha: null,
  };

  const { control, handleSubmit, errors, setError, clearErrors } =
    useFormHook(DEFAULT_VALUES);

  const { onSubmit } = useRecover(captcha, setError);

  const onChange = () => {
    clearErrors();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} alignItems={"center"}>
        <TextField
          name="username"
          type="text"
          label="Correo electronico o usuario"
          variant="standard"
          control={control}
          rules={RULES.requerido}
          error={Boolean(errors.username)}
          errmsg={errors.username}
        />
        <ReCAPTCHA
          ref={captcha}
          onChange={onChange}
          sitekey="6Le9AlYeAAAAALskHQ5SbU2-X4IW2lDDobyCOL1S"
        />
        {errors.captcha && (
          <Alert severity="warning">No esta validado por el captcha</Alert>
        )}
        <Button sx={{ width: "100%" }} variant="contained" type="submit">
          Recuperar contraseña
        </Button>
      </Stack>
    </form>
  );
};

export default memo(RecoverForm);
