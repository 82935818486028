/* @componentes */
import React, { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginFrom from './components/LoginFrom';
import Links from 'components/presentationals/Links/Links';
/* @Interfaces */
import IPage from '../../interfaces/page';
import { Helmet } from 'react-helmet-async';
/* @Mui */

import { makeStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material';
import tema from '../../utils/theme';
import NAVIGATION from 'routes/navigation';
import { useAppSelector } from 'hooks/useRedux';
import { searchItemLocal } from 'utils/helpers';
import { localToken } from 'constants/index';

const myStyles = makeStyles({
  texto: {
    color: tema.principal.text,
    fontSize: tema.principal.fontTitulo
  }
});

const Login: React.FC<IPage> = () => {
  
   const token = searchItemLocal(localToken);
   const navigate = useNavigate();



  useEffect(
    () => {
      if (token) navigate(NAVIGATION.HOME);
    },
    [token, navigate])

  return (
    <>
      <Helmet>
        <title>SAMINCYT - Iniciar Sesión</title>
      </Helmet>
      <Stack p={4} spacing={2}>
      <Typography  className="page-title-login" component='h1'>
          INICIAR SESIÓN
        </Typography>
        <LoginFrom />
        <Links />
      </Stack>
    </>
  );
};

export default memo(Login);
