/* @Compoenetes */
import React, { useRef } from "react";
import { TextField } from "components/presentationals/Fields";
import SelectForm from "components/presentationals/Fields/TextFieldSelect";
import ReCAPTCHA from "react-google-recaptcha";
import AlertInfo from "components/AlertInfo";
/* @Mui */
import {
  Stack,
  Button,
  Grid,
  Slide,
  CircularProgress,
  Alert,
} from "@mui/material";
/* Hooks */
import { useRegister } from "../hooks/useRegister";
import { useFormHook } from "hooks/useFormHook";

/* @Constant */
import { NATIONALITY, RULES } from "constants/index";
import RestForm from "./RestForm";

const DEFAULT_VALUES = {
  fecha_nacimiento: "",
  nacionalidad: "V",
  cedula: "",
  email: "",
  username: "",
  password: "",
  password2: "",
  captcha: null,
};
const RegisterForm = () => {
  let captcha: any = useRef(null);

  const {
    control,
    handleSubmit,
    errors,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useFormHook(DEFAULT_VALUES);

  const { onSubmit, isLoading, isVerified, onClear } = useRegister(
    captcha,
    reset,
    getValues,
    clearErrors,
    setError
  );

  const onChange = () => {
    clearErrors();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5} alignItems={"center"}>
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid item xs={4}>
            <SelectForm
              name="nacionalidad"
              variant="standard"
              control={control}
              options={NATIONALITY}
              label="Nacionalidad"
              defaultValue="V"
              rules={RULES.requerido}
              error={Boolean(errors.nacionalidad)}
              readOnly={isVerified ? true : false}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              name="cedula"
              type="text"
              label="Cedula"
              // value={getValues("cedula")}
              variant="standard"
              control={control}
              rules={RULES.cedula}
              error={Boolean(errors.cedula)}
              errmsg={errors.cedula}
              readOnly={isVerified ? true : false}
            />
          </Grid>
        </Grid>

        <TextField
          name="fecha_nacimiento"
          type="date"
          label="Fecha de nacimiento"
          variant="standard"
          control={control}
          labelProps={{ shrink: true }}
          rules={RULES.requerido}
          // value={getValues("fecha_nacimiento")}
          readOnly={isVerified ? true : false}
          error={Boolean(errors.fecha_nacimiento)}
          errmsg={errors.fecha_nacimiento}
        />
        <Slide
          style={{ width: "100%" }}
          direction="up"
          in={isVerified}
          mountOnEnter
          unmountOnExit
        >
          <RestForm
            control={control}
            errors={errors}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
          />
        </Slide>
        <ReCAPTCHA
          ref={captcha}
          onChange={onChange}
          sitekey="6Le9AlYeAAAAALskHQ5SbU2-X4IW2lDDobyCOL1S"
        />
        {errors.captcha && (
          <Alert severity="warning">No esta validado por el captcha</Alert>
        )}

        {isVerified && (
          <Button
            sx={{ width: "100%" }}
            onClick={() => onClear()}
            color="error"
            variant="contained"
            type="reset"
          >
            Cancelar
          </Button>
        )}
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : !isVerified ? (
            "Verificar"
          ) : (
            "Registrar"
          )}
        </Button>
        <AlertInfo
          texto="Todo los campos de texto seran transformado a miniuscula exceptuando el
      campo de contraseña contraseña"
        />
      </Stack>
    </form>
  );
};

export default RegisterForm;
