import {
  getMonth,
  getYear,
  parse,
  intervalToDuration,
  format,
  getDate,
  addMonths,
} from 'date-fns';
import es from 'date-fns/locale/es';
import jwt_decode from "jwt-decode";

/**
 * Get Item form local storage
 * @param key: string
 * @returns Item: any | null
 */
export const searchItemLocal = (key: string) => {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  return JSON.parse(item);
};

/**
 * Get payload from decoded token from local storage
 * @param key: string
 * @returns object
 */
export const searchPayloadLocal = (key: string) => {

try {
  const item = localStorage.getItem(key);
  if (!item) {
    return '';
  }
  const decoded: any = jwt_decode(JSON.parse(item));
  return decoded;
} catch (error) {
  console.log(error)
}
};

/**
 * Set new Item localStorage
 * @param key: string
 * @param item: any
 * @returns Item: any | false
 */
export const setItemLocal = (key: string, item: any) => {
  localStorage.setItem(key, JSON.stringify(item));

  const newIitem = searchItemLocal(key);

  if (newIitem) {
    return newIitem;
  }
  return false;
};

/**
 * Remove item from localStorage
 * @param key: string
 * @returns Item: any | null
 */
export const removeItemLocal = (key: string) => {
  const searchItem = searchItemLocal(key);
  if (searchItem) {
    localStorage.removeItem(key);
    return searchItem;
  }
  return null;
};

export const getYearHelper = (payload: any) => {
  return getYear(payload) + '';
};

export const getMonthHelper = (payload: any) => {
  return getMonth(payload) + 1 + '';
};

export const getTimeWork = (payload: any) => {
  const dateFormated = parse(payload, 'yyyy-MM-dd', new Date());
  const tiempo = intervalToDuration({ start: dateFormated, end: new Date() });

  return `${tiempo.years} año(s), ${tiempo.months} mes(es) y ${tiempo.days} dia(s)`;
};

export const getFormatDateString = (date: any, formatDate: string) => {
  if (typeof date === 'string') {
    const dateFormated = parse(date, 'yyyy-MM-dd', new Date());
    return format(dateFormated, formatDate);
  }

  return format(date, formatDate);
};

export const capitalize = (str: string, lower = false) => {
  return (lower ? str.toLowerCase() : str).replace(
    /(?:^|\s|["'([{])+\S/g,
    (match) => match.toUpperCase()
  );
};

export const getYearString = (year: any) => {
  if (typeof year === 'string') {
    const dateFormated = parse(year, 'yyyy-MM-dd', new Date());
    return getYear(dateFormated);
  }
  if (year === null) {
    return format(new Date(), 'yyyy', { locale: es });
  }

  return getYear(year);
};
export const getDayString = (day: any) => {
  if (typeof day === 'string') {
    const dateFormated = parse(day, 'yyyy-MM-dd', new Date());
    return getDate(dateFormated);
  }
  return getDate(day);
};

export const getMonthString = (month: any) => {
  if (typeof month === 'string') {
    const dateFormated = parse(month, 'yyyy-MM-dd', new Date());
    return format(dateFormated, 'MMMM', { locale: es });
  }

  if (month === null) {
    return format(new Date(), 'MMMM', { locale: es });
  }

  return format(month, 'MMMM', { locale: es });
};

export const oneMonthstring = (date: any) => {
  return format(addMonths(date, 1), 'dd/MM/yyyy');
};

export const getPermiso = (
  permisos: string[] | undefined,
  permiso: string,
) => {

  return permisos?.some((per:any) => per.name  === permiso);
};
