import { Alert } from '@mui/material';
const AlertInfo = ({ texto }: { texto: string }) => {
  return (
    <Alert
      sx={{ fontSize: '11px', marginTop: '6px', padding: '0px 16px' }}
      severity='info'>
      {texto}
    </Alert>
  );
};

export default AlertInfo;
