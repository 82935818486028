// Vendors
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';
import { alertsMessages } from './appSlice';
// Types
import { IAction, IBenefits } from '../interfaces/index';
import { BenefitService } from '../services/benefits';

const PREFIX = 'BENEFITS';

const initialState: IBenefits = {
  isLoading: false,
  benefit: {
    monto: ''
  }
};

const appAdapter = createEntityAdapter<IBenefits>({});

/**
     *   Prestacion por cedula
     * @descp Recibe la cedula del trabajor 
     * @param cedula :object, 
     * ,
        @return mixed
     */
export const getBenefit = createAsyncThunk(
  `${PREFIX}/GET_MODULE`,
  async (payload: any, { dispatch }) => {
    try {
      const response = await BenefitService({ payload, authRequire: true });
      return response?.data?.prestacionesData;
    } catch (err: any) {
      dispatch(alertsMessages(err?.response?.data?.alert));
      throw Error();
    }
  }
);

export const moduleSlice = createSlice({
  name: PREFIX,
  initialState: appAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (build) => {
    /* Caso ModuleService */
    build.addCase(getBenefit.pending, (state) => {
      state.isLoading = true;
    });

    build.addCase(getBenefit.fulfilled, (state, action: IAction) => {
      state.isLoading = initialState.isLoading;
      state.benefit = action.payload;
    });
    build.addCase(getBenefit.rejected, (state) => {
      state.isLoading = initialState.isLoading;
    });
  }
});

// Actions
//export const {} = moduleSlice.actions;

// Reducer
export default moduleSlice.reducer;
