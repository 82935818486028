/* @componentes */
import { memo } from 'react';
import RegisterForm from './components/RegisterForm';
import Links from 'components/presentationals/Links/Links';
import { Helmet } from 'react-helmet-async';
/* @Mui */

import { Stack, Typography } from '@mui/material';
;


const Register = () => {

  return (
    <>
      <Helmet>
        <title>SAMINCYT - Registrarse</title>
      </Helmet>
      <Stack p={4} spacing={2}>
      <Typography  className="page-title-login" component='h1'>
          REGISTRARSE
        </Typography>
        <RegisterForm />
        <Links />
      </Stack>
    </>
  );
};

export default memo(Register);
