import { TRoute } from 'types';
import { typeRouteEnum } from 'utils/enums';
import { NAVIGATION } from './navigation';

import Arc from 'pages/arc/Arc';
import Benefits from 'pages/benefits/Benefits';
import Construccion from 'pages/construccion/Construccion';
import Dash from 'pages/dashboard/Dash';
import Login from 'pages/login/Login';
import Modules from 'pages/modules/Modules';
import Profile from 'pages/profile/Profile';
import Receipt from 'pages/receipt/Receipt';
import Recover from 'pages/recover/Recover';
import Register from 'pages/register/Register';
import Users from 'pages/users/Users';
import VerifingDocument from 'pages/verifingDocument/VerifingDocument';
import VerifyDocument from 'pages/verifyDocument/VerifyDocument';
import UpdatePassword from 'pages/updatePassword/UpdatePassword';
import Documents from 'pages/documents/Documents';
import Profiles from 'pages/profiles/Profiles';
import Permissions from 'pages/permissions/Permission';

export const routes: TRoute[] = [
  {
    label: 'Principal',
    path: NAVIGATION.HOME,
    component: Dash,
    typeRoute: typeRouteEnum.PRIVATE
  },

  {
    label: 'Validar Documento',
    path: NAVIGATION.VALIDATE_DOCUMENT,
    component: VerifyDocument,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'Validando Documento',
    path: NAVIGATION.VALIDATE_DOCUMENT_BY_CODE,
    component: VerifingDocument,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'Construccion',
    path: NAVIGATION.CONSTRUCTION,
    component: Construccion,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'Registrarse',
    path: NAVIGATION.REGISTER,
    component: Register,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'iniciar-sesion',
    path: NAVIGATION.LOGIN,
    component: Login,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'Recuperar',
    path: NAVIGATION.RECOVER_PASSWORD,
    component: Recover,
    typeRoute: typeRouteEnum.PUBLIC
  },
  {
    label: 'Recuperar',
    path: NAVIGATION.UPDATE_PASSWORD,
    component: UpdatePassword,
    typeRoute: typeRouteEnum.PUBLIC
  },

  {
    label: 'Perfil',
    path: NAVIGATION.GET_PROFILE,
    component: Profile,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'Recibo',
    path: NAVIGATION.GET_RECEIPT,
    component: Receipt,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'Arc',
    path: NAVIGATION.GET_ARC,
    component: Arc,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'usuarios',
    path: NAVIGATION.GET_USERS,
    component: Users,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'administracion de modulos',
    path: NAVIGATION.MODULES,
    component: Modules,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'presaciones sociales',
    path: NAVIGATION.BENEFITS,
    component: Benefits,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'administracion de perfiles',
    path: NAVIGATION.MODULES_PROFILES,
    component: Profiles,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'administracion de permisos',
    path: NAVIGATION.MODULES_PERMISSIONS,
    component: Permissions,
    typeRoute: typeRouteEnum.PRIVATE
  },
  {
    label: 'administracion de documentos',
    path: NAVIGATION.ADMIND_DOCS,
    component: Documents,
    typeRoute: typeRouteEnum.PRIVATE
  }
];
