import { TextField, TextFieldSelect } from 'components/presentationals/Fields';
import { Form } from 'components/presentationals/Formularies';

import { RULES } from 'constants/index';

import { useFormHook } from 'hooks/useFormHook';
import React from 'react';
import { TFormProps } from 'types';
import { Grid, CircularProgress, Button } from '@mui/material';

import { PERMISSIONS } from 'utils/enums';

const FormPermissions: React.FC<TFormProps> = ({
  onSubmit,
  loadingService,
  rowData
}) => {
  const { data } = rowData;

  const navOptions = Object.values(PERMISSIONS).map((a: any) => ({
    id: a,
    name: a
  }));

  const { control, errors, handleSubmit } = useFormHook(data);

  return (
    <Form onSubmit={onSubmit} handleSubmit={handleSubmit}>
      <Grid container alignItems="flex-end" spacing={3}>
        <Grid item xs={12}>
          <TextFieldSelect
            name="name"
            label="Nombre del Permiso"
            control={control}
            variant="standard"
            options={navOptions}
            rules={RULES.requerido}
            autoFocus={true}
            error={Boolean(errors.path)}
            /*      defaultValue={(data as { name: string }).name} */
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            type="text"
            label="Descripcion del Permiso"
            variant="standard"
            control={control}
            multiline
            rules={RULES.requerido}
            error={Boolean(errors.description)}
            errmsg={errors.description}
          />
        </Grid>
      </Grid>

      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={loadingService}>
        {loadingService ? <CircularProgress size={24} /> : 'Actualizar'}
      </Button>
    </Form>
  );
};

export default FormPermissions;
