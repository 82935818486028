import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
/* interfaces */
import IPage from '../../interfaces/page';
import { IPROPS } from 'interfaces/index';
import { SubmitHandler } from 'react-hook-form';

/* MUI */
import { Typography, Container } from '@mui/material';
import FormArc from './components/FormArc';
/* Helpers */
import { getPermiso, getYearHelper, searchPayloadLocal } from 'utils/helpers';
/* Redux */
import { clearErrorsServer } from 'reducers/appSlice';
import { userARCWorker } from 'reducers/userSlice';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { localToken } from '../../constants';
import { PERMISSIONS } from 'utils/enums';

const Arc: React.FC<IPage> = () => {
  const DEFAULT_VALUES: IPROPS | undefined = {
    anio: new Date(),
    cedula: searchPayloadLocal(localToken).cedula
  };

  const dispatch = useAppDispatch();

  const { menu } = useAppSelector(state => state.authReducer);
  const {permissions} = menu as unknown as IPage 
  


  const { isGenerating } = useAppSelector((state) => state.userReducer);
  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    const payload = {
      cedula: data.cedula,
      anio: getYearHelper(data.anio)
    };

    await dispatch(userARCWorker(payload));
    await clearErrorsServer();
  };
  return (
    <>
      <Helmet>
        <title>SAMINCYT - ARC</title>
      </Helmet>
      <section>
        <Container maxWidth='sm'>
          <Typography  className="page-title" component='h1'>
            ARC
          </Typography>
          <FormArc 
          disabledButton={getPermiso(
            permissions,
            PERMISSIONS.DISABLED_BUTTON_ARC,
          )}
          onSubmit={onSubmit} isLoading={isGenerating} defaultValues={DEFAULT_VALUES} />
        </Container>
      </section>
    </>
  );
};

export default memo(Arc);
