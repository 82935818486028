import React from 'react';
import FormDatePicker from 'components/presentationals/Fields/FormDatePicker';

/* MUI */
import { Stack, Button } from '@mui/material';
/* hooks */
import { useFormHook } from 'hooks/useFormHook';

/* interfaces */
import { IPROPS } from 'interfaces/index';

/* constants */
import { QUINCENA } from 'constants/index';

/* Redux */

/* helpers */

import TextFieldSelect from 'components/presentationals/Fields/TextFieldSelect';
import { SubmitHandler } from 'react-hook-form';

const FormReceipt: React.FC<FormReceiptProps> = ({
  onSubmit,
  defaultValues,
  disabledButton
}) => {
  const { control, handleSubmit, getValues } = useFormHook(defaultValues);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormDatePicker
          views={['year', 'month']}
          name="date"
          label="Seleccione año y mes"
          value={getValues('date')}
          control={control}
          openTo="month"
        />
        <TextFieldSelect
          name="quincena"
          variant="standard"
          control={control}
          options={QUINCENA}
          label="Seleccione la quincena"
          defaultValue="1"
        />

        <Button
          fullWidth
          variant="contained"
          type="submit"
          disabled={disabledButton}
          sx={{ marginTop: 4 }}>
          Generar recibo de pago
        </Button>
      </Stack>
    </form>
  );
};
export type FormReceiptProps = {
  onSubmit: SubmitHandler<IPROPS>;
  defaultValues: IPROPS;
  disabledButton?: boolean;
};
export default FormReceipt;
