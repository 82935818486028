// Vendors
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';
import { localToken } from 'constants/index';
/* Interfaces */
import { Auth, IAction, iPayloadString } from 'interfaces/index';
import jwt_decode from "jwt-decode";
/* Services */
import {
  LoginService,
  RecoverService,
  RegisterService,
  UpdateService,
  VerifyService
} from 'services/auth';
/* helpers */
import { removeItemLocal, searchItemLocal, setItemLocal } from 'utils/helpers';
import { alertsMessages } from './appSlice';


const PREFIX = 'AUTH';
const authAdapter = createEntityAdapter<Auth>({});
const initialState: Auth = {
  isAuthenticated: false,
  isLoading: false,
  success: false,
  isVerified: false,
  user: {
    cedula: '',
    nacionalidad: '',
    username: '',
    email: '',
    profiles: []
  },
  menu: {}
};

/**
 * Iniciar sesión
 * @param username :string, 
 * @param password :string, 
  @return mixed
 */
export const login = createAsyncThunk(
  `${PREFIX}/INICIAR-SESION`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await LoginService({ payload });
      const decoded: any = jwt_decode(response?.data?.token);
      const { cedula, email, nacionalidad, username, menu } = decoded;
      const token = response.data.token.replace(`"`, '').replace(`"`);
      setItemLocal(localToken, token);
      const profiles = menu.profiles.map(
        (profile: any) => profile['users_profiles.profile.name']
      );
      delete menu.profiles;
      return {
        user: { cedula, email, nacionalidad, username, profiles },
        menu
      };
    } catch (error: any) {
  
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

/**
 * Verificar usuario en nomina
 * @param cedula :string, 
 * @param nacionalidad :string, 
 * @param fecha_nacimiento: string,
  @return mixed
 */
export const verifyWorker = createAsyncThunk(
  `${PREFIX}/VERIFICAR-TRABAJADOR`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await VerifyService({ payload });
      return dispatch(alertsMessages(response?.data?.alert));
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

/**
 * Registrar usuario
 * @param username :string, 
 * @param email :string, 
 * @param password: string,
 * @param nacionalidad: string, 
 * @param cedula: string
  @return mixed
 */
export const registerWorker = createAsyncThunk(
  `${PREFIX}/REGISTRAR-TRABAJADOR`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await RegisterService({ payload });
      return dispatch(alertsMessages(response?.data?.alert));
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);
/**
 * Recuperar contrase{a}
 * @param username :string, 
  @return mixed
 */
export const recoverWorker = createAsyncThunk(
  `${PREFIX}/RECUPERAR-CONTRASEÑA`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await RecoverService({ payload });

      return dispatch(alertsMessages(response?.data?.alert));
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);
export const updateWorker = createAsyncThunk(
  `${PREFIX}/ACTUALIZAR-CONTRASEÑA`,
  async (payload: iPayloadString, { dispatch }) => {
    try {
      const response = await UpdateService({ payload, authRequire: true });
      dispatch(alertsMessages(response?.data?.alert));
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  }
);

/**
 * Verificar token al refrescar *
  @return mixed
 */
export const reloadingBrowser = createAsyncThunk(
  `${PREFIX}/RELOADING-BROWSER`,
  () => {
    const token = searchItemLocal(localToken);
    if (!token) {
      removeItemLocal(localToken);
    }
  

    const decoded: any = jwt_decode(token);
 
    if (decoded.exp > Math.floor(Date.now() / 1000)) {
      const { cedula, email, nacionalidad, username, menu } = decoded;
      const profiles = menu.profiles.map(
        (profile: any) => profile['users_profiles.profile.name']
      );
      delete menu.profiles;
      return {
        user: { cedula, email, nacionalidad, username, profiles },
        menu
      };
    } else removeItemLocal(localToken);
  }
);

/**
 * Cerrar sesion *
  @return mixed
 */
export const logout = createAsyncThunk(`${PREFIX}/logout`, () =>
  removeItemLocal(localToken)

);

export const authSlice = createSlice({
  name: PREFIX,
  initialState: authAdapter.getInitialState(initialState),
  reducers: {
    clearVerified(state) {
      state.isVerified = false;
    },
    authenticated(state) {
      state.isAuthenticated = true;
    },
    expiredAuth(state) {
      state.isAuthenticated = false;
    }
  },
  extraReducers: (build) => {
    /* Caso LoginWorker */
    build.addCase(login.pending, (state) => {
      state.isLoading = true;
    });

    build.addCase(login.fulfilled, (state, { payload }: IAction) => {
      state.isLoading = initialState.isLoading;
      state.isAuthenticated = true;
      state.user = payload.user;
      state.menu = payload.menu;
    });
    build.addCase(login.rejected, (state) => {
      state.isLoading = initialState.isLoading;
    });

    /* caso Logout */
    build.addCase(logout.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuthenticated = initialState.isAuthenticated;
      state.user = initialState.user;
      state.menu = initialState.menu;
    });
    build.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });

    /* Caso VerifyWorker */
    build.addCase(verifyWorker.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(verifyWorker.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
      state.isVerified = true;
    });
    build.addCase(verifyWorker.rejected, (state) => {
      state.isLoading = initialState.isLoading;
      state.isVerified = initialState.isVerified;
    });

    /* Caso RegisterWorker */
    build.addCase(registerWorker.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(registerWorker.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
      state.isVerified = initialState.isVerified;
    });
    build.addCase(registerWorker.rejected, (state) => {
      state.isLoading = initialState.isLoading;
      state.isVerified = initialState.isVerified;
    });
    /* Caso RecoverWorker */
    build.addCase(recoverWorker.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(recoverWorker.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
    });
    build.addCase(recoverWorker.rejected, (state) => {
      state.isLoading = initialState.isLoading;
    });
    /* Caso UpdateWorker */
    build.addCase(updateWorker.pending, (state) => {
      state.isLoading = true;
      state.success = initialState.success;
    });
    build.addCase(updateWorker.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
      state.success = true;
    });
    build.addCase(updateWorker.rejected, (state) => {
      state.isLoading = initialState.isLoading;
      state.success = initialState.success;
    });

    /* Caso ReloadBrowser */
    build.addCase(reloadingBrowser.fulfilled, (state, { payload }: IAction) => {
      state.isAuthenticated = true;
      state.user = payload.user;
      state.menu = payload.menu;
    });
    build.addCase(reloadingBrowser.rejected, (state) => {
      state.isAuthenticated = false;
      state.user = initialState.user;
    });
  }
});

//Actions
export const { clearVerified } = authSlice.actions;
// Reducer
export default authSlice.reducer;
