/* interfaces */
import { IPROPS } from 'interfaces/index';
import { SubmitHandler, FieldError } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useVerify = (
  captcha: any,
  setError: (name: string, error: FieldError) => void
) => {
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    if (captcha?.current.getValue()) {
      navigate(`/validardocumento/${data.codigo}`);
    } else {
      setError('captcha', {
        type: 'validado',
        message: 'No esta validado por el captcha',
      });
    }
  };

  return { onSubmit };
};
