/* @componentes */
import { Container, Paper, Stack, Typography } from '@mui/material';
/* @Mui */
import { makeStyles } from '@mui/styles';
import { PREFIX_DASHBOARD } from 'routes/navigation';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import VerifyForm from './components/VerifyForm';

const myStyles = makeStyles({

  link: {
    alignItems: 'right',
    color: '#2166a1',
    fontWeight: 'bold',
    padding: '10px 0 0 10px'
  }
});

const VerifyDocument = () => {
  const classes = myStyles();
  return (
    <>
      <Helmet>
        <title>SAMINCYT - Validar documento</title>
      </Helmet>
      <Container maxWidth='xs' sx={{ margin: 'auto' }}>
     
          <Link to={PREFIX_DASHBOARD}>
            <Stack className={classes.link} direction='row'>
              Inicio
            </Stack>
          </Link>
          <Stack p={4} spacing={2}>
          <Typography  className="page-title-login" component='h1'>
              VALIDAR DOCUMENTO
            </Typography>
            <VerifyForm />
          </Stack>

      </Container>
    </>
  );
};

export default memo(VerifyDocument);
