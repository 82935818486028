import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import appReducer from 'reducers/appSlice';
import authReducer from 'reducers/authSlice';
import userReducer from 'reducers/userSlice';
import moduleReducer from 'reducers/moduleSlice';
import benefitReducer from 'reducers/benefitSlice';

export const store = configureStore({
  reducer: {
    appReducer,
    authReducer,
    userReducer,
    moduleReducer,
    benefitReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: process.env.NODE_ENV === 'development'
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
