import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Container } from '@mui/material';
import PasswordForm from './components/PasswordForm';
import { Helmet } from 'react-helmet-async';
const Profile: React.FC = () => {
  return (
    <>
     <Helmet>
        <title>SAMINCYT - Perfil</title>
      </Helmet>
    <section>
      <Container maxWidth="lg">
      <Typography  className="page-title" component='h1'>
          PERFIL DEL USUARIO
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>Cambio de contraseña</Typography>
          </AccordionSummary>
          <AccordionDetails />
          <PasswordForm />
        </Accordion>
      </Container>
    </section>
    </>
  );
};

export default Profile;
