import React from "react";
import { makeStyles } from "@mui/styles";
import tema from "utils/theme";
import { getYearString } from "utils/helpers";
import { Stack, Typography } from "@mui/material";

const myStyles = makeStyles({
  root: {
    background: tema.principal.dark,
    padding: "20px 10px 0 10px",
  },
  texto: {
    color: "#fff",
    margin: "5px 0",
    textAlign: "center",
  },
});

const FooterLogin: React.FC = () => {
  const classes = myStyles();
  return (
    <footer className={classes.root}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className={classes.texto} fontSize={14} variant="subtitle2">
          {`© ${getYearString(
            new Date()
          )} MINCYT | Oficina de Tecnologías de la Información y la Comunicación`}
        </Typography>
        <Typography className={classes.texto} fontSize={14} variant="subtitle2">
          Oficina de Gestión Humana
        </Typography>
      </Stack>
    </footer>
  );
};

export default FooterLogin;
