import { request } from 'config/request';
import { iRequest } from 'interfaces/api';
import { Tparam } from 'types';

export const UserService = (props: iRequest) => {
  const { authRequire, payload, params } = props;

  return request('/constancia', {
    method: 'post',
    data: { params: payload },
    authRequire,
    params: params
  });
};

export const UserRecibo = (props: iRequest) => {
  const { authRequire, payload, params } = props;
  return request('/recibo', {
    method: 'post',
    data: { params: payload },
    authRequire,
    params: params
  });
};

export const UserChangePassword = (props: iRequest) => {
  const { authRequire, payload, params } = props;
  return request('/userupdatepassword', {
    method: 'post',
    data: { params: payload },
    authRequire,
    params: params
  });
};

export const FindConstancyService = (props: iRequest) => {
  const { authRequire, payload, params } = props;
  return request(`/seekconstancy/${payload}`, {
    method: 'get',
    authRequire,
    params: params
  });
};

export const createConstancyService = (props: iRequest) => {
  const { authRequire, payload, params } = props;
  return request(`/createconstancy`, {
    method: 'post',
    responseType: 'blob',
    data: { params: payload },
    authRequire,
    params: params
  });
};

export const createArcService = (props: iRequest) => {
  const { authRequire, payload, params } = props;

  return request(`/createarc`, {
    method: 'post',
    responseType: 'blob',
    data: { params: payload },
    authRequire,
    params: params
  });
};

/**
 * Obtener todos los usuarios de la API
 * @method getAllUsersService
 * @param props: {iRequest}
 * @returns void
 */
/* export const getAllUsersService = (
  props: iRequest
): Promise<AxiosResult<unknown>> => {
  const { authRequire } = props;
  return request('/users/all', {
    method: 'GET',
    authRequire,
    params
  });
}; */

export const getAllUsersService = ({ params, authRequire }: iRequest) => {
  const { page, size, ...rest } = params as Tparam;
  return request('/users/all', {
    method: 'get',
    authRequire,
    params: { page: page + 1, size, ...rest }
  });
};

export const updateUserServices = ({
  params,
  payload,
  authRequire
}: iRequest) => {
  return request(`/users/${params}`, {
    method: 'put',
    authRequire,
    data: payload
  });
};
