import { CustomButton } from 'components/presentationals/CustomButton';
import { TextField } from 'components/presentationals/Fields';
import { Form } from 'components/presentationals/Formularies';

import { NATIONALITY, RULES } from 'constants/index';

import { useFormHook } from 'hooks/useFormHook';
import React, { useState, useEffect } from 'react';
import { TFormProps, TOptionsChecked } from 'types';
import {  Grid, Typography, CircularProgress, Button } from '@mui/material';
import SelectForm from 'components/presentationals/Fields/TextFieldSelect';
import { useAppDispatch } from 'hooks/useRedux';
import { getAllProfilesServices } from 'services';
import CheckField from 'components/presentationals/Fields/CheckField';
import { TCheckedState } from 'types/common';
import SwitchField from 'components/presentationals/Fields/SwitchField';
import { alertsMessages } from 'reducers/appSlice';

const FormUsers: React.FC<TFormProps> = ({
  onSubmit,
  loadingService,
  rowData
}) => {
  const dispatch = useAppDispatch()
  const { data } = rowData;

  const { control, errors, handleSubmit } = useFormHook(data);
  const objCheckedDefaultProfiles = data.users_profiles
    ? data.users_profiles.reduce((acc, el) => {
      if (!acc[el.profile_id]) {
        acc[el.profile_id] = true;
      }
      return acc;
    }, {} as TCheckedState)
    : {};

  const [profiles, setProfiles] = useState<TOptionsChecked[]>([]);
  const [checked, setChecked] = useState(data.is_active ?? false);
  const [checkedProfiles, setCheckedProfiles] = useState<TCheckedState>(
    objCheckedDefaultProfiles
  );

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    stateChecked: TCheckedState,
    setCheckedState: React.Dispatch<React.SetStateAction<TCheckedState>>
  ) => {
    setCheckedState({
      ...stateChecked,
      [event.target.value]: event.target.checked
    });
    const currentId = event.target.value;
    const currentChecked = event.target.checked;

    const isIntersected = Object.keys(stateChecked).some(
      el => Number(el) === Number(currentId)
    );

    if (!isIntersected && currentChecked) {
      /* Insertar */
      setCheckedState(prev => ({
        ...prev,
        [currentId]: currentChecked
      }));
    } else {
      const newCheckdList = Object.keys({ ...stateChecked })
        .filter(el => el !== currentId)
        .reduce((acc, el) => {
          if (!acc[el]) {
            acc[el] = true;
          }
          return acc;
        }, {} as Record<string | number, boolean>);

      setCheckedState(newCheckdList);
    }
  };
  const handleChecked = ({
    target: { checked }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(checked);
  };


  const getProfiles = async () => {
    try {
      const { data } = await getAllProfilesServices({
        params: {}
      });
      setProfiles(data.data as TOptionsChecked[]);
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    }
  };

  const preSubmit = async (data: unknown) => {
    const profilesToArray = Object.keys(checkedProfiles);

    if (!profilesToArray.length) {
      /*  openAlert('¡Debe seleccionar al menos un Rol!', { variant: 'error' }); */
      return;
    }

    const newPayload: Record<string, string | number[]> = {
      ...data as Record<string, string | number[]>,
      profiles: profilesToArray.map(profileId => Number(profileId))
    };

    onSubmit(newPayload);
  };

  useEffect(() => {
    getProfiles();
  }, []);
  return (
    <Form onSubmit={preSubmit} handleSubmit={handleSubmit}>

      <Grid item xs={12}>
        <Typography color="text" fontWeight="bold">
          Estado
        </Typography>
        <SwitchField
          control={control}
          name="is_active"
          checked={checked}
          handleChecked={handleChecked}
          label={checked ? 'Activo' : 'Inactivo'}
        />
      </Grid>
      <Grid container alignItems="flex-end" spacing={3}>
        <Grid item xs={3}>
          <SelectForm
            name="nacionalidad"
            variant="standard"
            control={control}
            options={NATIONALITY}
            label="Nacionalidad"
            defaultValue="V"
            rules={RULES.requerido}
            error={Boolean(errors.nacionalidad)}
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            name="cedula"
            type="text"
            label="Cedula"
            variant="standard"
            control={control}
            rules={RULES.cedula}
            error={Boolean(errors.cedula)}
            errmsg={errors.cedula}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="username"
            type="text"
            label="Usuario"
            variant="standard"
            control={control}
            rules={RULES.username}
            error={Boolean(errors.username)}
            errmsg={errors.username}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="email"
            type="text"
            label="Correo"
            variant="standard"
            control={control}
            rules={RULES.correo}
            error={Boolean(errors.email)}
            errmsg={errors.email}
          />
        </Grid>

      </Grid>
      <CheckField
        name="profiles"
        label="Asignar Rol(es)"
        control={control}
        options={profiles}
        checkedState={checkedProfiles}
        handleCheck={handleCheck}
        setCheckedState={setCheckedProfiles}
        itemLabel="name"
        error={!Object.keys(checkedProfiles).length}
      />

      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={loadingService}
      >
        {loadingService ? <CircularProgress size={24} /> : "Actualizar"}
      </Button>

    </Form>
  );
};

export default FormUsers;
