import React, { memo } from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import style from './Table.module.scss';
import { TPageState, TTable } from 'types';
/* hooks */

const Table: React.FC<TTable> = ({
  columns,
  pageState,
  setPageState,
  onFilterChange
}) => {
  const { total, isLoading, page, pageSize, data } = pageState;

  const [rowCountState, setRowCountState] = React.useState<number>(total || 0);
  React.useEffect(
    () => {
      setRowCountState(
        prevRowCountState => (total !== undefined ? total : prevRowCountState)
      );
    },
    [total, setRowCountState]
  );
  return (
    <div
      style={{
        minHeight: '550px',
        width: '100%',
        height: '1px'
      }}>
      <DataGrid
        className={style['main_table']}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rowsPerPageOptions={[5, 10, 50]}
        pagination
        rows={data}
        rowCount={rowCountState}
        page={page}
        loading={isLoading}
        pageSize={pageSize}
        disableSelectionOnClick={true}
        onPageChange={newPage => {
          setPageState((old: TPageState) => ({ ...old, page: newPage }));
        }}
        onPageSizeChange={newPageSize =>
          setPageState((old: TPageState) => ({
            ...old,
            pageSize: newPageSize
          }))}
        paginationMode="server"
        filterMode="server"
        onFilterModelChange={onFilterChange}
        /* onCellClick={handleCellClick} */
      />
    </div>
  );
};

export default memo(Table);
