import React from 'react';
import { Typography } from '@mui/material';
import { ITextForm } from 'interfaces';
import { makeStyles } from '@mui/styles';

const myStyles = makeStyles({
  titulo: {
    color: '#424242'
  }
});
const TextForm: React.FC<ITextForm> = ({ titulo, texto }) => {
  const classes = myStyles();
  return (
    <>
      <Typography
        className={classes.titulo}
        fontWeight='bold'
        fontSize={17}
        variant='subtitle1'
        align='center'>
        {titulo}
      </Typography>
      <Typography align='center' fontSize={14} variant='subtitle2'>
        {texto}
      </Typography>
    </>
  );
};

export default TextForm;
