import { request } from 'config/request';
import { iRequest } from 'interfaces/api';

export const LoginService = (props: iRequest) => {
  const { params, authRequire, payload } = props;
  return request('/login', {
    method: 'post',
    data: { params: payload },
    authRequire,
    params: params
  });
};

export const RecoverService = (props: iRequest) => {
  const { params, authRequire, payload } = props;
  return request('/recoverpassword', {
    method: 'post',
    data: { params: payload },
    authRequire,
    params: params
  });
};
export const UpdateService = (props: iRequest) => {
  const { params, authRequire, payload } = props;
  return request('/updatepassword', {
    method: 'post',
    data: { params: payload },
    authRequire,
    params: params
  });
};

export const VerifyService = (props: iRequest) => {
  const { params, authRequire, payload } = props;
  return request('/checknomina', {
    method: 'post',
    data: { params: payload },
    authRequire,
    params: params
  });
};

export const RegisterService = (props: iRequest) => {
  const { params, authRequire, payload } = props;
  return request('/register', {
    method: 'post',
    data: { params: payload },
    authRequire,
    params: params
  });
};
