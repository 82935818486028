import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import DryIcon from '@mui/icons-material/Dry';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HealingIcon from '@mui/icons-material/Healing';
import HiveIcon from '@mui/icons-material/Hive';
import HomeIcon from '@mui/icons-material/Home';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MedicationIcon from '@mui/icons-material/Medication';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import UserGroupIcon from '@mui/icons-material/VerifiedUser';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { TIconsList } from 'types';
/**
 * @title listado de iconos disponibles en el sistema
 */
export const iconList: TIconsList = {
  default: DashboardIcon,
  HomeOutlinedIcon: HomeIcon,
  PeopleIcon: UserGroupIcon,
  DescriptionIcon: DescriptionIcon,
  HandshakeIcon: HandshakeIcon,
  AssignmentIcon: AssignmentIcon,
  ViewModuleIcon: ViewModuleIcon,
  PeopleAltIcon: PeopleAltIcon,
  AdminPanelSettingsIcon: AdminPanelSettingsIcon,
  SupervisedUserCircleIcon: SupervisedUserCircleIcon,
  WheelchairPickupIcon: WheelchairPickupIcon,
  HiveIcon: HiveIcon,
  DryIcon: DryIcon,
  CoronavirusIcon: CoronavirusIcon,
  CalendarMonthIcon: CalendarMonthIcon,
  HealingIcon: HealingIcon,
  MedicalServicesIcon: MedicalServicesIcon,
  MedicationIcon: MedicationIcon,
  MedicalInformationIcon: MedicalInformationIcon,
  VaccinesIcon: VaccinesIcon,
  FindInPageIcon: FindInPageIcon
};
