import React, { memo, useState,useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
/* interfaces */
import IPage from '../../interfaces/page';

/* MUI */
import { Typography, Container, Slide, Button, Stack, CircularProgress } from '@mui/material';
import SearchWorker from './components/SearchWorker';

import { UserRecibo, UserService } from 'services/user';
import { useAppDispatch } from 'hooks/useRedux';
import { alertsMessages, clearErrorsServer } from 'reducers/appSlice';
import { TOpenModals, TUseFormHookProps } from 'types';
import WorkerData from './components/WorkerData';
import { IPROPS, IUserConstanciaData } from 'interfaces';
import { makeStyles } from '@mui/styles';

import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArticleIcon from '@mui/icons-material/Article';
import { clearRecibo, createConstancy, userARCWorker } from 'reducers/userSlice';
import randomstring from 'randomstring';
import { CustomModal } from 'components/presentationals/CustomModal';
import { isEmpty } from 'lodash';
import createRecepit from 'pdfs/receipt';
import FormReceipt from 'pages/receipt/components/FormReceipt';
import { getMonthHelper, getYearHelper } from 'utils/helpers';
import FormArc from 'pages/arc/components/FormArc';

const myStyles = makeStyles({
 root:{
  display: 'grid !important',
  gridTemplateRows: '1fr 1fr auto',
  gap: '20px'
      }
});

const Documents: React.FC<IPage> = () => {

  const classes = myStyles()
  const dispatch = useAppDispatch();
  const [worker, setWorker] = useState<Partial<IUserConstanciaData> | null>(null)
  const [loadingService, setLoadingService] = useState({
    loadingSearch:false,
    loadingConstancy:false,
    loadingReceipt:false,
    loadingArc:false,
  });
  const [openModal, setOpenModal] = React.useState<TOpenModals>({
    modalReceipt: false,
    modalArc: false,
  });
  const [workerReceipt, setWorkerReceipt] = useState({})


  const DEFAULT_VALUES_RECEIPT: IPROPS | undefined = {
    date: new Date(),
    quincena: '1',
    cedula: worker?.cedula || ''
  };

  const DEFAULT_VALUES_ARC: IPROPS | undefined = {
    anio: new Date(),
    cedula:  worker?.cedula || ''
  };

  const onSubmitConstancy = async (data: TUseFormHookProps) => {
    try {
      setLoadingService((prevState)=> ({...prevState,loadingSearch:true}))
      const response = await UserService({ payload: data, authRequire: true });
      const datos = response?.data?.constanciaData;
      if (!datos.cedula) {
        return dispatch(alertsMessages(
          {
            message: "Trabajador no encontrado",
            name: "Error"
          }));

      }
      setWorker(datos)
    } catch (error: any) {
      dispatch(alertsMessages(error?.response?.data?.alert));
      throw Error();
    } finally {
      setLoadingService((prevState)=> ({...prevState,loadingSearch:false}))
    } 
  };

  const onSubmitArc = async (data) => {
    setLoadingService((prevState)=> ({...prevState,loadingArc:true}))
    const payload = {
      cedula: worker?.cedula,
      anio: getYearHelper(data.anio)
    };
    await dispatch(userARCWorker(payload));
    await clearErrorsServer();
    setLoadingService((prevState)=> ({...prevState,loadingArc:false}))
  };


  const onSubmitReceipt =async (data)=>{
    try {
      const payload: IPROPS = {
        anio: getYearHelper(data.date),
        mes: getMonthHelper(data.date),
        quincena: data.quincena,
        cedula: data.cedula
      };
      const response = await UserRecibo({ payload, authRequire: true });
       setWorkerReceipt(response?.data?.reciboData)
      await clearErrorsServer();
    } catch (error) {
   const {response} = error as {response:any}
      dispatch(alertsMessages(response?.data?.alert));
      throw Error();
    }
  }

  const handleConstancy = async () => {
    setLoadingService((prevState)=> ({...prevState,loadingConstancy:true}))
    const codigo = randomstring.generate(7);
    await dispatch(createConstancy({ codigo, cedula:worker?.cedula, admin:true }));
    setLoadingService((prevState)=> ({...prevState,loadingConstancy:false}))
  };



  const handleArc = ()=>{
 setOpenModal({ modalArc: true });
  }

  const handleReceipt = ()=>{
    setOpenModal({ modalReceipt: true });
  }


  useEffect(
    () => {

      if (!isEmpty(workerReceipt)) {

        createRecepit({ reciboData: workerReceipt, userData:worker });
        dispatch(clearRecibo());
      }
    },
    [workerReceipt, worker, dispatch])

  return (
    <>
      <Helmet>
        <title>SAMINCYT - ARC</title>
      </Helmet>
      <section>
        <Container maxWidth='sm' className={classes.root}>
          <Typography className="page-title" component='h1'>
            ADMINISTRAR DOCUMENTOS
          </Typography>
          <SearchWorker onSubmit={onSubmitConstancy} loadingService={loadingService.loadingSearch as boolean} />
           <Slide  style={{ width: "100%" }} direction="right" in={!!worker} mountOnEnter unmountOnExit>
       <Stack alignItems='center' spacing={2}>
        <WorkerData workerData={worker as IUserConstanciaData} />

    <Stack flexWrap='initial' sx={{width:'100%', fontSize:'12px'}} direction='row' spacing={2} >
      <Button  onClick={handleConstancy} disabled={loadingService.loadingConstancy}   variant="contained" fullWidth  startIcon={<ArticleIcon />}>
        {loadingService.loadingConstancy ? 
        <CircularProgress size={24} /> : 'Constancia'}
        </Button>

        <Button  onClick={handleReceipt} disabled={loadingService.loadingReceipt}   variant="contained" fullWidth  startIcon={<AssignmentIcon />}>
        {loadingService.loadingReceipt ? 
        <CircularProgress size={24} /> : 'Recibo De Pago'}
        </Button>

        <Button  onClick={handleArc} disabled={loadingService.loadingArc} variant="contained" fullWidth  startIcon={<DescriptionIcon />}>
        {loadingService.loadingArc ? 
        <CircularProgress size={24} /> : 'ARC'}
        </Button>
      </Stack>
    </Stack> 
          </Slide>

        </Container>

        <CustomModal
        modalTitle={`Generar recibo de pago para el trabajador ${worker?.primer_apellido} ${worker?.primer_nombre}`}
        openModal={openModal.modalReceipt as boolean}
        setOpenModal={setOpenModal}>
        <FormReceipt
          onSubmit={onSubmitReceipt} defaultValues={DEFAULT_VALUES_RECEIPT as IPROPS} />
      </CustomModal>

      
      <CustomModal
        modalTitle={`Generar documento ARC para el trabajador ${worker?.primer_apellido} ${worker?.primer_nombre}`}
        openModal={openModal.modalArc as boolean}
        setOpenModal={setOpenModal}>
        <FormArc
          onSubmit={onSubmitArc} defaultValues={DEFAULT_VALUES_ARC as IPROPS} isLoading={loadingService.loadingArc} />
      </CustomModal>



      </section>
    </>
  );
};

export default memo(Documents);
