import { BoxIcons } from 'components/presentationals/BoxIcons';
import { CustomButton } from 'components/presentationals/CustomButton';
import { TextField, TextFieldSelect } from 'components/presentationals/Fields';
import { Form } from 'components/presentationals/Formularies';
import { Loader } from 'components/presentationals/Loader';
import { NAVIGATION } from 'routes/navigation';
import { iconList, RULES } from 'constants/index';
import { useAlert } from 'hooks';
import { useFormHook } from 'hooks/useFormHook';
import { useState } from 'react';
import { TFormProps, TIconsList } from 'types';

const FormModules = ({ onSubmit, loadingService, rowData }: TFormProps) => {
  const { data, isNewRecord } = rowData;
  const { openAlert } = useAlert();
  const [iconSelected, setIconSelected] = useState(
    !isNewRecord ? (data as { name_icon: string }).name_icon : ''
  );

  const { control, errors, handleSubmit } = useFormHook(data);

  const navOptions = Object.values(NAVIGATION).map((a: any) => ({
    id: a,
    name: a
  }));

  const displayOptions = [
    {
      id: 'principal',
      name: 'En barra lateral (principal)'
    },
    {
      id: 'secundario',
      name: 'No mostrar en barra lateral (principal)'
    }
  ];

  const preSubmit = async (data: unknown) => {
    const payload = { ...data as any, name_icon: iconSelected };
    if (!iconSelected.trim()) {
      openAlert('¡Debe seleccionar un ícono!', 'error');
      return;
    }
    onSubmit(payload);
  };
  return (
    <Form onSubmit={preSubmit} handleSubmit={handleSubmit}>
      <TextField
        name="name"
        label="Nombre del módulo"
        control={control}
        variant="standard"
        rules={RULES.no_special}
        error={Boolean(errors.name)}
        errmsg={errors.name}
        autoFocus
      />
      <TextField
        name="description"
        label="Descripción del modulo"
        control={control}
        variant="standard"
        rules={RULES.no_special}
        multiline={true}
        error={Boolean(errors.description)}
        errmsg={errors.description}
      />
      <TextFieldSelect
        name="path"
        label="Ruta del módulo"
        control={control}
        variant="standard"
        options={navOptions}
        rules={RULES.requerido}
        autoFocus={true}
        error={Boolean(errors.path)}
        defaultValue={(data as { path: string }).path}
      />
      <TextFieldSelect
        name="display"
        label="Mostrar módulo"
        control={control}
        variant="standard"
        options={displayOptions}
        rules={RULES.requerido}
        autoFocus={true}
        error={Boolean(errors.display)}
        // defaultValue={(data as { display: any }).display}
      />
      <BoxIcons
        handleCheckIcon={(icon: any) => setIconSelected(icon)}
        iconListArray={Object.entries(iconList)}
        iconList={(iconList as unknown) as Record<string, TIconsList>}
        defaultIconName={iconSelected}
      />
      {loadingService
        ? <Loader />
        : <CustomButton
            label={isNewRecord ? 'Crear' : 'Actualizar'}
            typeVariant="outlined"
            typeAction="submit"
            disabled={loadingService}
          />}
    </Form>
  );
};

export default FormModules;
