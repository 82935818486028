// Vendors
import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
//Redux store
import { store } from 'store';

import reportWebVitals from './reportWebVitals';

import App from './pages/app/App';
import { HelmetProvider } from 'react-helmet-async';
// constants
import { SNACKBARS_IN_DISPLAY } from 'constants/index';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <HelmetProvider>
      <SnackbarProvider maxSnack={SNACKBARS_IN_DISPLAY}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </HelmetProvider>
  </Provider>
  //  </React.StrictMode>
);

reportWebVitals();
