import { Outlet } from 'react-router-dom';

import { FunctionComponent, PropsWithChildren } from 'react';
import LoginHeader from '../Login/LoginHeader';
import FooterLogin from '../Login/FooterLogin';

/* @Mui */
import { makeStyles } from '@mui/styles';
import { Container, Paper, Grid } from '@mui/material';
import tema from 'utils/theme';

/* @Images */
import logo from 'static/img/autogestion.jpg';

const myStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    minHeight: '100vh'
  },
  main: {
    background: tema.principal.dark,
    height: '100%',
    display: 'flex',
    padding: '2.5rem 0'
  },
  container: {
    margin: 'auto'
  },
  figure: {
    height: '100%',
    minHeight: '350px',
    width: '100%',

    '& img': {
      width: '100%',
      objectFit: 'cover',
      filter: 'brightness(0.8)',
      borderRadius: '14px 0 0 14px'
    }
  }
});

const PublicLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const classes = myStyles();

  return (
    <section className={classes.root}>
      <LoginHeader />
      <main>
        <div className={classes.main}>
          <Container className={classes.container} maxWidth="md">
            <Paper elevation={2} sx={{ borderRadius: 4 }}>
              <Grid container>
                <Grid sx={{ display: { xs: 'none', sm: 'block' } }} item sm={6}>
                  <figure className={classes.figure}>
                    <img src={logo} alt="autogestion" />
                  </figure>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {children ? children : <Outlet />}
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </div>
      </main>
      <FooterLogin />
    </section>
  );
};

export default PublicLayout;
