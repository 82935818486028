import React, { memo } from 'react';
import { Controller } from 'react-hook-form';

/* @Mui */
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import tema from 'utils/theme';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import local from 'date-fns/locale/es';
/* @interfaces */
import { IFormFields } from 'interfaces/FormFields';

const myStyles = makeStyles({
  root: {
    position: 'relative'
  },
  error: {
    position: 'absolute',
    margin: '5px',
    color: tema.principal.error
  }
});

const FormDatePicker: React.FC<IFormFields> = ({
  name = '',
  control,
  rules = {},
  variant = 'standard',
  label = '',
  error = false,
  errmsg = { message: '' },
  views = ['year'],
  openTo = 'year'
}) => {
  const classes = myStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={local}>
      <div className={classes.root}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, value } }) =>
            <DatePicker
              views={views}
              label={label}
              openTo={openTo}
              value={value}
              minDate={new Date('2000-01-01')}
              maxDate={new Date('2030-12-12')}
              onChange={e => onChange(e)}
              renderInput={params =>
                <TextField
                  {...params}
                  error={error ? true : false}
                  variant={variant}
                  style={{ width: '100%' }}
                  value={value}
                />}
            />}
        />
        {error &&
          <p className={classes.error}>
            {errmsg.message}
          </p>}
      </div>
    </LocalizationProvider>
  );
};

export default memo(FormDatePicker);
