import React, { forwardRef, useMemo } from 'react';
import { IUserConstanciaData } from 'interfaces/index';
import { Stack } from '@mui/material';
import ListGrid from 'components/ListGrid';
import { getTimeWork } from 'utils/helpers';
import { isUndefined } from 'lodash';

const WorkerData: React.FC<TWorkerDataProps> = forwardRef(({ workerData },ref) => {
    const dataList = useMemo(
        () => [
            {
                title: 'DEPENDENCIA',
                content: workerData?.nombre,
                lg:12 ,
                sm:12 
              },
          {
            title: 'NOMBRES',
            content: `${workerData?.primer_apellido} ${workerData?.segundo_apellido}`,
            lg:4
          },
          {
            title: 'APELLIDOS',
            content: `${workerData?.primer_nombre} ${workerData?.segundo_nombre}`,
            lg:4  
          },
          {
            title: 'CEDULA',
            content: `${workerData?.nacionalidad}-${workerData?.cedula}`,
            lg:4  
          },
          {
            title: 'TIPO PERSONAL',
            content: workerData?.tipo_personal,
            lg:4  
          },
        
          {
            title: 'CARGO',
            content: workerData?.descripcion_cargo,
            lg:4  
          },
          {
            title: 'TIEMPO DE SERVICIO',
            content: !isUndefined(workerData?.fecha_ingreso)
              ? getTimeWork(workerData?.fecha_ingreso)
              : '',
              lg:4
          }
        
        ],
        [workerData]
      );

  return (
    <Stack ref={ref}>

<ListGrid data={dataList} loading={false} />

    </Stack>
  );
});
export type TWorkerDataProps = {
  workerData: IUserConstanciaData;
};
export default WorkerData;
