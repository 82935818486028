// interface options {
//   id: string;
//   nombre: string;
// }
export { RULES } from './rules';
export { NATIONALITY } from './nationality';
export { DISPLAY } from './display';
export { QUINCENA } from './quincena';
export { iconList } from './icons';
export const localToken = 'x-mppct-token';
export const recoverToken = 'x-mppct-recover';
export const SITE_KEY = '6Le9AlYeAAAAALskHQ5SbU2-X4IW2lDDobyCOL1S';

export const SNACKBARS_IN_DISPLAY: number = 5;
