/* @Mui */
import { Button, CircularProgress, Stack } from '@mui/material';
import PasswdField from 'components/presentationals/Fields/PasswdField';
/* Constants */
import { recoverToken, RULES } from 'constants/index';
import { useFormHook } from 'hooks/useFormHook';
import { useAppSelector } from 'hooks/useRedux';
import React, { memo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
/* Utils */
import { removeItemLocal, setItemLocal } from 'utils/helpers';
/* Redux */
/* Hooks */
import { useUpdate } from '../hooks/useUpdate';

const LoginFrom: React.FC = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const DEFAULT_VALUES = {
    password: '',
    password2: ''
  };

  const { success } = useAppSelector((state) => state.authReducer);

  const {
    control,
    handleSubmit,
    errors,
    handleBlurPassword,
    getValues,
    setError
  } = useFormHook(DEFAULT_VALUES);

  const { onSubmit, isLoading } = useUpdate(getValues, setError);

  useEffect(() => {
    setItemLocal(recoverToken, params?.token);
  }, [params?.token]);

  useEffect(() => {
    if (success) {
      removeItemLocal(recoverToken);
      navigate('/iniciar-sesion');
    }
  }, [success, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction='column' spacing={2}>
        <PasswdField
          name='password'
          control={control}
          label='Nueva Contraseña'
          variant='standard'
          rules={RULES.contraseña}
          error={Boolean(errors.password)}
          errmsg={errors.password}
        />
        <PasswdField
          name='password2'
          control={control}
          label='Repetir Contraseña'
          variant='standard'
          rules={RULES.contraseña}
          handleBlur={handleBlurPassword}
          error={Boolean(errors.password2)}
          errmsg={errors.password2}
        />
      </Stack>
      <Button
        fullWidth
        variant='contained'
        type='submit'
        sx={{ marginTop: 4 }}
        disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : 'Actualizar'}
      </Button>
    </form>
  );
};

export default memo(LoginFrom);
