import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IParams } from 'interfaces/index';

/* interfaces */
import { RootState } from 'store';
/* Redux */
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { findConstancy } from 'reducers/userSlice';

export const useVerifing = () => {
  const { verifyData } = useAppSelector(
    (state: RootState) => state.userReducer
  );
  const dispatch = useAppDispatch();
  const params = useParams();
  const { codigo } = params as IParams;

  useEffect(
    () => {
      dispatch(findConstancy(codigo as any)); // revision
    },
    [codigo, dispatch]
  );

  return {
    verifyData
  };
};
