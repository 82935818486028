import AlertInfo from "components/AlertInfo";
import { TextField } from "components/presentationals/Fields";
import PasswdField from "components/presentationals/Fields/PasswdField";
import { RULES, SITE_KEY } from "constants/index";
import React, { memo, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
/* @Mui */
import { Alert, Button, CircularProgress, Stack } from "@mui/material";

/* Hooks */
import { useFormHook } from "hooks/useFormHook";
import { useLogin } from "../hooks/useLogin";

const LoginFrom: React.FC = () => {
  let captcha: any = useRef(null);

  const DEFAULT_VALUES = {
    username: "",
    password: "",
    captcha: null,
  };
  const { control, handleSubmit, errors, setError, clearErrors } =
    useFormHook(DEFAULT_VALUES);

  const { onSubmit, isLoading } = useLogin(captcha, clearErrors, setError);

  const onChange = () => {
    clearErrors();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={3} alignItems="center">
        <TextField
          name="username"
          label="Usuario"
          control={control}
          variant="standard"
          // value={getValues("username")}
          error={Boolean(errors.username)}
          errmsg={errors.username}
          rules={RULES.requerido}
        />
        <PasswdField
          name="password"
          control={control}
          label="Contraseña"
          variant="standard"
          rules={RULES.contraseña}
          error={Boolean(errors.password)}
          errmsg={errors.password}
        />
        <ReCAPTCHA ref={captcha} onChange={onChange} sitekey={SITE_KEY} />
        {errors.captcha && (
          <Alert severity="warning">No esta validado por el captcha</Alert>
        )}
      </Stack>

      <Button
        fullWidth
        variant="contained"
        type="submit"
        sx={{ marginTop: 4 }}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "ingresar"}
      </Button>
        <AlertInfo
          texto="Todo los campos de texto seran transformado a miniuscula exceptuando el
      campo de contraseña contraseña"
      />
    </form>
  );
};

export default memo(LoginFrom);
