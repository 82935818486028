import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
/* hooks */
import { useAppSelector } from 'hooks/useRedux';
/* Mui */
import { myStylesList } from 'static/styles-mui/stylesMenu';

import {
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography
} from '@mui/material';

/* enums */
import { DISPLAY } from 'config/enums';
import { iconList } from '../constants';


const ListMenu: React.FC<ListMenuProps> = ({ movil }) => {

  const classes = myStylesList({ movil });
  const { menu } = useAppSelector((state) => state.authReducer);
  return (
    <List component='nav' className={classes.nav}>
      {menu &&
        menu?.modules &&
        menu?.modules?.length &&
        menu.modules.map(({ id, name, path, icon, display }: any) => {
          const Xicon = iconList[icon];
          return (  
            DISPLAY.principal === display && (
              <NavLink
                key={id}
                to={path}
                end
                className={({ isActive }) =>
              isActive ? `${classes.item} ${classes.active}` : classes.item
            }>
                <ListItem button>
                  {Xicon ? (
                    <ListItemIcon>
                      <Tooltip arrow title={name} placement='right'>
                        <Xicon />
                      </Tooltip>
                    </ListItemIcon>
                  ) : null}
                  <Typography fontSize={16} variant='subtitle2'>
                    {name}
                  </Typography>
                </ListItem>
              </NavLink>
            )
          );
        })}
    </List>
  );
};

/**
 * params para componente ListMenu
 * @method type ListMenuProps
 * @param movil?: {boolean} Verificar si es boolean (true, false)
 */
export type ListMenuProps = { movil: boolean };
export default memo(ListMenu);
