// Vendors
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';
import { alertsMessages } from './appSlice';
// Types
import { IAction, ModuleState } from '../interfaces/index';
// import { ModuleService, ModulesService } from 'services/modules';

const PREFIX = 'MODULE';

const initialState: ModuleState = {
  isLoading: false,
  module: {
    id: '',
    name: '',
    path: '',
    name_icon: '',
    description: '',
    display: ''
  },
  modules: {
    count: null,
    rows: []
  }
};

const appAdapter = createEntityAdapter<ModuleState>({});

/**
   *   Modulo por id
   * @descp recibe el id del modulo
   * @param id :object, 
   * ,
      @return mixed
   */
// export const getModule = createAsyncThunk(
//   `${PREFIX}/GET_MODULE`,
//   async (payload: any, { dispatch }) => {
//     try {
//       const response = await ModuleService({ payload, authRequire: true });
//     } catch (err: any) {
//       dispatch(alertsMessages(err?.response?.data?.alert));
//       throw Error();
//     }
//   }
// );
/**
   *   Todos los Modulos
   * @descp recibe los modulos del sistema
   * ,
      @return mixed
   */
// s

export const moduleSlice = createSlice({
  name: PREFIX,
  initialState: appAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (build) => {
    /* Caso ModuleService */
    // build.addCase(getModule.pending, (state) => {
    //   state.isLoading = true;
    // });

    // build.addCase(getModule.fulfilled, (state) => {
    //   state.isLoading = initialState.isLoading;
    // });
    // build.addCase(getModule.rejected, (state) => {
    //   state.isLoading = initialState.isLoading;
    // });

    // /* Caso ModulesService */
    // build.addCase(getModules.pending, (state) => {
    //   state.isLoading = true;
    // });

    // build.addCase(getModules.fulfilled, (state, action) => {
    //   state.isLoading = initialState.isLoading;
    //   state.modules = action.payload;
    // });
    // build.addCase(getModules.rejected, (state) => {
    //   state.isLoading = initialState.isLoading;
    // });
  }
});

// Actions
//export const {} = moduleSlice.actions;

// Reducer
export default moduleSlice.reducer;
