import React, { forwardRef, memo } from "react";
import PasswdField from "components/presentationals/Fields/PasswdField";
import { TextField } from "components/presentationals/Fields";
import { RULES } from "constants/index";

/* @Mui */
import { Stack } from "@mui/material";

const RestForm: React.FC<any> = forwardRef(
  ({ setError, getValues, clearErrors, control, errors }, ref) => {
    const handleBlurPassword = () => {
      if (getValues("password") !== getValues("password2")) {
        setError("password2", {
          type: "igualdad",
          message: "contraseñas no son iguales",
        });
      } else {
        clearErrors();
      }
    };

    return (
      <Stack ref={ref} spacing={4} sx={{ width: "100%" }}>
        <PasswdField
          name="password"
          label="Contraseña"
          variant="standard"
          control={control}
          rules={RULES.contraseña}
          error={Boolean(errors.password)}
          errmsg={errors.password}
        />
        <PasswdField
          name="password2"
          label="Repita la contraseña"
          handleBlur={handleBlurPassword}
          variant="standard"
          control={control}
          rules={RULES.contraseña}
          error={Boolean(errors.password2)}
          errmsg={errors.password2}
        />

        <TextField
          name="username"
          type="text"
          label="Usuario"
          variant="standard"
          control={control}
          rules={RULES.username}
          error={Boolean(errors.username)}
          errmsg={errors.username}
        />

        <TextField
          name="email"
          type="email"
          label="Correo"
          variant="standard"
          control={control}
          rules={RULES.correo}
          error={Boolean(errors.email)}
          errmsg={errors.email}
        />
      </Stack>
    );
  }
);

export default memo(RestForm);
