import { request } from 'config/request';

// get all profiles
export function getAllProfilesServices({ params, authRequire }: any) {
  const { page, size } = params as any;
  return request('/profiles', {
    method: 'get',
    authRequire,
    params: { page: page + 1, size }
  });
}
// create profiles
export function createProfilesServices({ payload, authRequire }: any) {
  return request('/profiles', {
    method: 'post',
    authRequire,
    data: payload
  });
}
// update profiles
export function updateProfilesServices({ payload, params, authRequire }: any) {
  return request(`/profiles/${params}`, {
    method: 'put',
    data: payload,
    authRequire
  });
}
// delete profiles
export function deleteProfilesServices({ params, authRequire }: any) {
  return request(`/profiles/${params}`, {
    method: 'delete',
    authRequire
  });
}
