/**
 *
 * @method enum PERMISSIONS
 */
export enum PERMISSIONS {
  DISPLAY_BUTTON_CONSTANCY = 'MOSTRAR_BOTON_CONSTANCIA',
  DISABLED_BUTTON_CONSTANCY = 'DESABILITAR_BOTON_CONSTANCIA',
  DISABLED_BUTTON_RECEIPT = 'DESABILITAR_BOTON_RECIBO',
  DISABLED_BUTTON_ARC = 'DESABILITAR_BOTON_ARC'
}
