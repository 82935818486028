import { request } from 'config/request';
import { iRequest } from 'interfaces/api';

export const BenefitService = (props: iRequest) => {
  const { params, authRequire, payload } = props;
  return request('/prestaciones', {
    method: 'post',
    data: { params: payload },
    authRequire,
    params: params
  });
};
