import { useState } from 'react';
import Paper from '@mui/material/Paper';
/* @Mui */
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { toggleDrawer } from 'reducers/appSlice';
import tema from 'utils/theme';
import BarApp from '../BarApp/BarApp';
import FooterLogin from '../Login/FooterLogin';
import MenuDrawer from '../../MenuDrawer';
import MovilDrawer from '../../MovilDrawer';
import { localToken } from '../../../constants';
import { searchItemLocal } from 'utils/helpers';
import { useEffect } from 'react';
import NAVIGATION from '../../../routes/navigation';
import { useWindowSize } from 'hooks/useWindowSize';

const myStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateRows: ' 1fr auto',
    minHeight: '100vh',
    padding: '20px 32px',
    background: tema.principal.dark
  },
  main: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    [tema.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  container: {
    margin: 'auto'
  },
  contenido: {
    height: '100%',
    minHeight: '600px'
  },
  paper: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    alignItems: 'center',
    height: '100%',
    borderRadius: '30px !important',
    padding: '0 22px 40px 22px !important',
    background: '#f1f5f8 !important'
  }
});

const MainLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const classes = myStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleOpen = () => {
    dispatch(toggleDrawer());
  };
  const [movil, setMovil] = useState(false);

  const token = searchItemLocal(localToken);
  const { width } = useWindowSize();
  const { openMenu } = useAppSelector(state => state.appReducer);

  useEffect(
    () => {
      if (!token) navigate(NAVIGATION.LOGIN);
    },
    [token, navigate]
  );

  useEffect(
    () => {
      if (width < 600) {
        setMovil(prevState => !prevState);
      } else {
        setMovil(false);
      }
    },
    [width]
  );
  return (
    <section className={classes.root}>
      <main className={classes.main}>
        <MovilDrawer movil={movil} open={openMenu} handleOpen={handleOpen} />
        <MenuDrawer
          movil={movil}
          sx={{
            display: { xs: 'none', sm: 'block' }
          }}
          open={openMenu}
        />

        <div className={classes.contenido}>
          <Paper elevation={3} className={classes.paper}>
            <BarApp handleOpen={handleOpen} />
            {children ? children : <Outlet />}
          </Paper>
        </div>
      </main>
      <FooterLogin />
    </section>
  );
};

/**
 * @method type TMainLayoutProps
 */
export type TMainLayoutProps = {
  children?: ReactNode;
};

export default MainLayout;
