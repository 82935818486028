// Vendors
// Constants
import { localToken, recoverToken } from 'constants/index';
import { RequestOptionsType } from 'interfaces/api';
import jwt_decode from "jwt-decode";
// Utils
import { removeItemLocal, searchItemLocal } from 'utils/helpers';
import API from './api';

/**
 * Obtener headers
 * @param authRequired boolean
 * @returns headers
 */
const getHeaders = (authRequired: boolean) => {


  if (authRequired) {

    const authotization = searchItemLocal(localToken) ;
    const authotizationRecover = searchItemLocal(recoverToken) ;


    const tokenDecoded: any = authotization ? jwt_decode(authotization) : null
    const tokenRecoverDecoded: any = authotizationRecover ? jwt_decode(authotizationRecover) : null;


    if (tokenRecoverDecoded?.exp * 1000 < Date.now()) {
      return removeItemLocal(recoverToken);
    } else if (tokenDecoded?.exp * 1000 < Date.now()) {
      return removeItemLocal(localToken);
    }

    return authotization
      ? { 'x-mppct-token': `${authotization}` }
      : authotizationRecover
      ? { 'x-mppct-token-recover': `${authotizationRecover}` }
      : {};
  }

  return {};
};



export type AxiosResult<T> = {
  responseType?: any;
  data?: T;
  error?: any;
  code?: string;
  status?: number | string;
};

/**
 * Función genérica para comunicarse con la API
 * @param url: string
 * @param options: RequestOptionsType
 * @returns Promise: <AxiosResult<T>>
 */
export const request = async <T = any>(
  url: string,
  options: RequestOptionsType = {}
): Promise<AxiosResult<T>> => {
  const {
    method = 'get',
    data = {},
    authRequire = false,
    params,
    headers = {}
  } = options;


  return await API.request<T>({
    url,
    params,
    method,
    data,
    headers: { ...getHeaders(authRequire), ...headers }
  });
};
