import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    principal: {
      black?: string;
      white?: string;
      main?: string;
      dark?: string;
      error: string;
      warning?: string;
      success?: string;
      text?: string;
      fontTitulo?: string;
      fontSubTitulo?: string;
      fontTexto?: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    principal?: {
      black?: string;
      white?: string;
      main?: string;
      dark?: string;
      error?: string;
      warning?: string;
      success?: string;
      text?: string;
      fontTitulo?: string;
      fontSubTitulo?: string;
      fontTexto?: string;
    };
  }
}

const theme = createTheme({
  principal: {
    black: '#333',
    white: '#fff',
    main: '#03a9f4',
    dark: '#2166A1',
    error: '#d50000',
    warning: '#ffc400',
    success: '#00c853',
    text: '#999',
    fontTitulo: '30px',
    fontSubTitulo: '24px',
    fontTexto: '20px,'
  }
});

export default theme;
