/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { FormModules } from "components/pages/FormModules";

import { CustomButton } from "components/presentationals/CustomButton";
import { CustomModal } from "components/presentationals/CustomModal";
import { DialogConfirm } from "components/presentationals/DialogConfirm";
import { ActionsButtonsTable, Table } from "components/presentationals/Table";
import { useAlert } from "hooks";
import { useEffect, useMemo, useState } from "react";
import {
  createModulesServices,
  deleteModulesServices,
  getAllModulesServices,
  updateModulesServices,
} from "services/modules";
import { myStylesTablesPages } from "static/styles-mui";
import { TGenericResponse, TPageState } from "types";
import { useAppDispatch } from 'hooks/useRedux';
import { alertsMessages } from "reducers/appSlice";


const initialDataRow = {
  id: "",
  name: "",
  description: "",
  path: "",
  display: "",
  name_icon: "",
};

const Modules = () => {

  const classes = myStylesTablesPages();
  const dispatch = useAppDispatch()
  const { openAlert }   = useAlert();
  const [loadingService, setLoadingService] = useState(false);
  const [rowData, setRowData] = useState({
    data: initialDataRow,
    isNewRecord: true,
  });
  const [pageState, setPageState] = useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 5,
  });
  const [openModal, setOpenModal] = useState({
    dialogConfirm: false,
    modalCreate: false,
  });



  const getModules = async () => {
    try {
      setPageState((prev: TPageState) => ({ ...prev, isLoading: true }));
      const { data } = await getAllModulesServices({
        params: {
          page: pageState?.page,
          size: pageState?.pageSize,
        },
        authRequire: true,
      });


      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data?.data,
        total: data?.pageInfo?.totalRecords,
      }));
    } catch (err: any) {
      dispatch(alertsMessages(err?.response?.data?.alert));
    }
  };

  const onFilterChange = async (dataFilter) => {
    if (dataFilter?.items.length) {
      const { data } = await getAllModulesServices({
        params: {
          [dataFilter.items[0].columnField]: dataFilter.items[0].value,
          page: pageState?.page,
          size: pageState?.pageSize,
        },
        authRequire: true,
      });


      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data?.data,
        total: data?.pageInfo?.totalRecords,
      }));

    }

  }

  const handleCreate = () => {
    setOpenModal({ dialogConfirm: false, modalCreate: true });
    setRowData({ data: initialDataRow, isNewRecord: true });
  };

  const handleEditRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogConfirm: false, modalCreate: true });
    setRowData({ data: dataRow?.row, isNewRecord: false });
  };

  const handleDeleteRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogConfirm: true, modalCreate: false });
    setRowData({ data: dataRow?.row, isNewRecord: false });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 20 },
      { field: "name", headerName: "Nombre del modulo", width: 180 },
      {
        field: "description",
        headerName: "Descripción",
        minWidth: 130,
        flex: 1,
      },
      { field: "path", headerName: "Ruta", width: 140, flex: 1 },
      {
        field: "display",
        headerName: "Mostrar",
        width: 100,
        renderCell: ({ row: { display } }) =>
          display.includes("principal")
            ? "En barra lateral (principal)"
            : display,
        flex: 1,
      },
      { field: "name_icon", headerName: "Icono", width: 180 },
      {
        field: "actions",
        headerName: "Acciones",
        renderCell: (params) => (
          <ActionsButtonsTable
            handleEditRow={handleEditRow(params)}
            handleDeleteRow={handleDeleteRow(params)}
          />
        ),
        sortable: false,
        width: 100,
        headerAlign: "center",
        filterable: false,
        align: "center",
        disableColumnMenu: true,
        disableReorder: true,
      },
    ],
    []
  );

  const onDeleteRow = async (id: unknown) => {
    try {
      setLoadingService(true);
      (await deleteModulesServices({
        params: id as any,
        authRequire: true,
      })) as any;
      openAlert("Registro Eliminado Existosamente", "success");
    } catch (err) {
      openAlert("A ocurrido un error", "error");
    } finally {
      setLoadingService(false);
      getModules();
      setOpenModal((prevState) => ({ ...prevState, dialogConfirm: false }));
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setLoadingService(true);
      const id = data?.id;
      delete data.id;

      if (rowData?.isNewRecord) {
        (await createModulesServices({
          payload: data,
          authRequire: true,
        })) as TGenericResponse;
        openAlert("Se ha registrado existosamente", "success");
      } else {

        (await updateModulesServices({
          payload: data,
          params: id,
          authRequire: true,
        })) as TGenericResponse;
        openAlert("Se ha actualizado existosamente", "success");
      }
    } catch (err: any) {
      dispatch(alertsMessages(err?.response?.data?.alert));
    } finally {
      setLoadingService(false);
      setOpenModal((prevState) => ({ ...prevState, modalCreate: false }));
      getModules();
    }
  };

  useEffect(() => {
    getModules();
  }, [pageState.pageSize, pageState.page]);
  return (
    <>

      <Typography className="page-title" component='h1'>
        ADMINISTRACIÓN DE MODULOS
      </Typography>
      <div className={classes.tables_header}>
        <Button
          onClick={handleCreate}
          variant="contained"
        >
          Agregar Modulo
        </Button>

      </div>

      <Table
        pageState={pageState}
        setPageState={setPageState}
        columns={columns}
        onFilterChange={onFilterChange}
      />

      <CustomModal
        modalTitle={rowData.isNewRecord ? "Crear Módulo" : "Actualizar Módulo"}
        setOpenModal={setOpenModal}
        openModal={openModal.modalCreate}
      >
        <FormModules
          loadingService={loadingService}
          onSubmit={onSubmit}
          rowData={rowData}
        />
      </CustomModal>

      <DialogConfirm
        onDelete={onDeleteRow}
        title="Eliminar Modulo"
        openModal={openModal.dialogConfirm}
        setOpenModal={setOpenModal}
        idRow={rowData.data.id}
      >
        ¿Esta seguro de eliminar este modulo?
      </DialogConfirm>
    </>
  );
};

export default Modules;
