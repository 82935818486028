import React from 'react';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PREFIX_DASHBOARD } from 'routes/navigation';
import { Link } from 'react-router-dom';
import ConstruccionImg from 'static/img/construccion.png';
import { Helmet } from 'react-helmet-async';
import tema from '../../utils/theme';
const myStyles = makeStyles({
  construccion: {
    height: '350px'
  },
  texto: {
    color: '#fff',
    fontSize: tema.principal.fontTitulo
  },
  cont: {
    margin: '0 auto'
  },
  link: {
    fontSize: '30px',
    color: '#fff'
  }
});

function Construccion() {
  const classes = myStyles();
  return (
    <>
     <Helmet>
        <title>SAMINCYT - Pagina en Contrucción</title>
      </Helmet>
    <Stack className={classes.cont} justifyContent="center" alignItems="center">
    <Typography  className="page-title" component='h1'
        fontSize={30}>
        PAGINA EN CONSTRUCCIÓN
      </Typography>
      <picture className={classes.construccion}>
        <img src={ConstruccionImg} alt="Construccion" />
      </picture>
      <Typography
        className={classes.texto}
        textAlign="center"
        variant="body1"
        fontSize={30}>
        Esta pagina estara disponible en la brevedad posible
      </Typography>
      <Link to={PREFIX_DASHBOARD}>
        <Typography
          className={classes.texto}
          textAlign="center"
          variant="body1"
          fontSize={30}>
          INICIO
        </Typography>
      </Link>
    </Stack>
    </>
  );
}

export default Construccion;
