import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { IPROPS } from 'interfaces/index';
import { useEffect } from 'react';
import { FieldError, SubmitHandler } from 'react-hook-form';
import { userPasswordChange } from 'reducers/userSlice';

export const useSubmitPassword = (
  getValues: (payload?: string | string[]) => Object,
  reset: (
    values?: Record<string, any>,
    options?: Record<string, boolean>
  ) => void,
  clearErrors: (name?: string | string[]) => void,
  setError: (name: string, error: FieldError) => void
) => {
  const { success } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    if (success) {
      reset();
    }
  }, [reset, success]);

  const dispatch = useAppDispatch();

  const handleBlur = () => {
    if (getValues('passwordNew') !== getValues('passwordNew2')) {
      setError('passwordNew2', {
        type: 'igualdad',
        message: 'contraseñas no son iguales'
      });
      return false;
    } else {
      clearErrors();
      return true;
    }
  };

  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    if (handleBlur()) {
      dispatch(userPasswordChange(data));
    }
  };

  return { onSubmit, handleBlur };
};
