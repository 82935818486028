import { request } from 'config/request';

// get all Permissions
export function getAllPermissionsServices({ params, authRequire }: any) {
  const { page, size } = params as any;
  return request('/permissions', {
    method: 'get',
    authRequire,
    params: { page: page + 1, size }
  });
}
// create Permissions
export function createPermissionsServices({ payload, authRequire }: any) {
  return request('/permissions', {
    method: 'post',
    authRequire,
    data: payload
  });
}
// update Permissions
export function updatePermissionsServices({
  payload,
  params,
  authRequire
}: any) {
  return request(`/permissions/${params}`, {
    method: 'put',
    data: payload,
    authRequire
  });
}
// delete Permissions
export function deletePermissionsServices({ params, authRequire }: any) {
  return request(`/permissions/${params}`, {
    method: 'delete',
    authRequire
  });
}
