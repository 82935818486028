import { makeStyles } from '@mui/styles';

export const myStylesTablesPages = makeStyles({
  tables_header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '11px 12px',
    margin: '11px 12px'
  },
  tables__header_title: {
    fontSize: '24px',
    fontWeight: 'bolder'
  }
});
