import React,{ useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler } from 'react-hook-form';
import FormReceipt from './components/FormReceipt';
/* interfaces */
import IPage from '../../interfaces/page';
import { IPROPS } from 'interfaces/index';

/* MUI */
import { Typography, Container } from '@mui/material';

/* Helpers */
import { getMonthHelper, getPermiso, getYearHelper,searchPayloadLocal } from 'utils/helpers';
import { isEmpty } from 'lodash';
import { localToken } from 'constants/index';
import { PERMISSIONS } from 'utils/enums';
import createRecepit from 'pdfs/receipt';

/* Redux */
import { clearErrorsServer } from 'reducers/appSlice';
import {
  userReciboWorker,
  userDataWorker,
  clearRecibo
} from 'reducers/userSlice';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';

const Receipt: React.FC<IPage> = () => {

  const DEFAULT_VALUES: IPROPS | undefined = {
    date: new Date(),
    quincena: '1', 
    cedula: searchPayloadLocal(localToken).cedula|| null
  };

  const { reciboData, userData } = useAppSelector(state => state.userReducer);
  const { user,menu } = useAppSelector(state => state.authReducer);


  const {permissions} = menu as unknown as IPage 

  /* @State */
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IPROPS> = async data => {

    const payload: IPROPS = {
      anio: getYearHelper(data.date),
      mes: getMonthHelper(data.date),
      quincena: data.quincena,
      cedula: data.cedula
    };
    await dispatch(userReciboWorker(payload));
    await clearErrorsServer();
  };

  useEffect(
    () => {
      if (!isEmpty(user.cedula)) {
        dispatch(userDataWorker({ cedula: user.cedula }));
      }
    },
    [dispatch, user]
  );

  useEffect(
    () => {
      if (!isEmpty(reciboData)) {
        createRecepit({ reciboData, userData });
        dispatch(clearRecibo());
      }
    },
    [reciboData, userData, dispatch]
  );

  return (
    <>
      <Helmet>
        <title>SAMINCYT - Recibo de pago</title>
      </Helmet>
      <section>
        <Container maxWidth='sm'>
        <Typography  className="page-title" component='h1'>
            RECIBO DE PAGO
          </Typography>
          <FormReceipt
            disabledButton={getPermiso(
              permissions,
              PERMISSIONS.DISABLED_BUTTON_RECEIPT,
            )}
          onSubmit={onSubmit} defaultValues={DEFAULT_VALUES as IPROPS} />
        </Container>
      </section>
    </>
  );
};

export default Receipt;
