import { SubmitHandler, FieldError } from 'react-hook-form';
/* Redux */
import { clearErrorsServer } from 'reducers/appSlice';

import { updateWorker } from '../../../reducers/authSlice';

import { IPROPS } from 'interfaces/index';
/* Hooks */
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';

export const useUpdate = (
  getValues: (payload?: string | string[]) => Object,
  setError: (name: string, error: FieldError) => void
) => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.authReducer);

  const onSubmit: SubmitHandler<IPROPS> = async (data) => {
    if (getValues('password') !== getValues('password2')) {
      setError('password2', {
        type: 'igualdad',
        message: 'contraseñas no son iguales',
      });
    } else {
      await dispatch(updateWorker(data));
      await dispatch(clearErrorsServer());
    }
  };
  return { isLoading, onSubmit };
};
