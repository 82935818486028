import { request } from 'config/request';
import { iRequest } from 'interfaces/api';
import { Tparam } from 'types';

// get all modules
export const getAllModulesServices = ({ params, authRequire }: iRequest) => {
  const { page, size } = params as Tparam;
  return request('/modules', {
    method: 'get',
    authRequire,
    params: { page: page + 1, size }
  });
};
// create modules
export const createModulesServices = ({ payload, authRequire }: iRequest) => {
  return request('/modules', {
    method: 'post',
    authRequire,
    data: payload
  });
};
// update modules
export const updateModulesServices = ({
  payload,
  params,
  authRequire
}: iRequest) => {
  return request(`/modules/${params}`, {
    method: 'put',
    authRequire,
    data: payload
  });
};
// delete modules
export function deleteModulesServices({ params, authRequire }: iRequest) {
  return request(`/modules/${params}`, {
    method: 'delete',
    authRequire
  });
}
