import React, { useEffect, memo } from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ListGrid from 'components/ListGrid';

import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { Container } from '@mui/system';

import { getBenefit } from 'reducers/benefitSlice';
import { isEmpty } from 'lodash';



const Benefits: React.FC<{}> = () => {

  const dispatch = useAppDispatch();
  const { benefit } = useAppSelector((state) => state.benefitReducer);
  const { userData } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    if (!isEmpty(userData)) {
      dispatch(getBenefit({ cedula: userData.cedula + '' }));
    }
  }, [userData, dispatch]);

  const dataList = [
    {
      title: 'CEDULA',
      content: userData.cedula
    },
    {
      title: 'MONTO DEVENGADO',
      content: benefit.monto + ' Bs'
    },
    {
      title: 'MONTO APROBADO',
      content: `NO DISPONIBLE`
    },
    {
      title: 'FECHA SOLICITUD',
      content: `NO DISPONIBLE`
    },
    {
      title: 'FECHA DE APROBACIÓN',
      content: `NO DISPONIBLE`
    }
  ];
  return (
    <>
      <Helmet>
        <title>SAMINCYT - Prestaciones Sociales</title>
      </Helmet>
      <section>
      <Typography  className="page-title" component='h1'>
          PRESTACION SOCIALES
        </Typography>

        <ListGrid data={dataList} />
        <Tooltip title='Proximamente'>
          <Container maxWidth='xs'>
            <Button
              fullWidth
              variant='contained'
              type='submit'
              sx={{ marginTop: 4 }}
              disabled={true}>
              Solicitar prestaciones
            </Button>
          </Container>
        </Tooltip>
      </section>
    </>
  );
};

export default memo(Benefits);
