import { makeStyles } from '@mui/styles';
import tema from 'utils/theme';
import borde from 'static/img/borde.svg';
export const myStylesList = makeStyles({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  item: {
    '& > .MuiListItem-root': {
      borderRadius: (props: { movil: boolean }) =>{return props?.movil ? 'unset' : '15px 0 0 15px'},
      color: '#fff',
      fontWeight: 'bolder',
  
      '& svg': {
        color: '#fff',
      },
    },
    '& > .MuiListItem-root:hover': {
      background: '#f1f5f8',
      color: tema.principal.dark,
   
      '& svg': {
        color: tema.principal.dark,
      },
    },
  },
  active: {
    '& > .MuiListItem-root': {
      background: '#f1f5f8',
      borderRadius: '15px 0 0 15px',
      position: 'relative',
      color: '#2166a1',

      '&::before': {
        top: '100%',
      },
      '&::before, &::after ': {
        content: (props: { movil: boolean }) => (props?.movil ? 'unset' : '""'),
        height: '30px',
        width: '30px',
        backgroundSize: '100%',
        position: 'absolute',
        right: '0',
        backgroundImage: `url(${borde}) `,
        zIndex: 100,
      },
      '&::after': {
        transform: 'rotate(90deg) scale(1.04)',
        bottom: '100%',
      },
      '&:hover': {
        background: '#f1f5f8',
      },
      '& svg': {
        color: tema.principal.dark,
      },
    },
  },
});
