import { Backdrop } from "../Backdrop";
import { CircularProgress } from "../CircularProgress";
import styles from "./Loader.module.scss";
const Loader = () => {
  return (
    <Backdrop>
      <div className={styles["boxLoading"]}>
        <CircularProgress />
      </div>
    </Backdrop>
  );
};

export default Loader;
