import { IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { TBoxIconsProps, TItemListIcon, TMuiIcon } from "types";
import styles from "./BoxIcons.module.scss";

const BoxIcons = ({
  iconList = {},
  iconListArray = [],
  handleCheckIcon,
  defaultIconName = "",
  boxLabel = "Iconos",
}: TBoxIconsProps) => {
  const [iconSelected, setIconSelected] = useState(defaultIconName ?? "");
  const IconSelected = iconList[iconSelected] as unknown as TMuiIcon;

  const preHandleCheckIcon = (keyIcon: string) => {
    setIconSelected(keyIcon);
    handleCheckIcon(keyIcon);
  };

  return (
    <div className={styles["box-icons__container"]}>
      <Typography variant="subtitle1" className={styles["box-icons__title"]}>
        {boxLabel}
      </Typography>
      <div className={styles["box-icons__items"]}>
        {iconListArray.map((item: any) => {
          const Icon = item[1];
          const keyIcon = item[0] as any;
          return (
            <Tooltip key={keyIcon} title={keyIcon}>
              <IconButton onClick={() => preHandleCheckIcon(keyIcon)}>
                <Icon classes={styles["box-icons__icon"]} />
              </IconButton>
            </Tooltip>
          );
        })}
      </div>
      {iconSelected && (
        <Typography className={styles["box-icons__title-selected"]}>
          Icono seleccionado:{" "}
          <IconSelected className={styles["box-icons__icon-seleted"]} />
        </Typography>
      )}
    </div>
  );
};

export default BoxIcons;
