import React, { memo } from 'react';
import { IVALIDO } from 'interfaces';
import { Stack, Grid } from '@mui/material';
import TextForm from 'components/TextForm';
import { makeStyles } from '@mui/styles';

const myStyles = makeStyles({
  h2: {
    fontSize: '17px',
    color: '#424242'
  }
});

const Valido = ({ datos }: { datos: IVALIDO }) => {
  const classes = myStyles();

  const {
    codigo,
    cedula,
    descripcion_cargo,
    fecha_ven,
    nacionalidad,
    nombre,
    primer_apellido,
    segundo_apellido,
    primer_nombre,
    segundo_nombre,
    salario
  } = datos;

  return (
    <Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between">
        <h2 className={classes.h2}>{`Código: ${codigo}`}</h2>
        <h2 className={classes.h2}>{`Fecha de vencimiento: ${fecha_ven}`}</h2>
      </Stack>

      <Grid container>
        <Grid mt={2} item xs={12} sm={6}>
          <TextForm
            titulo="NOMBRES"
            texto={`${primer_apellido} ${segundo_apellido}`}
          />
        </Grid>
        <Grid mt={2} item xs={12} sm={6}>
          <TextForm
            titulo="APELLIDOS"
            texto={`${primer_nombre} ${segundo_nombre}`}
          />
        </Grid>
        <Grid mt={2} item xs={12} sm={6}>
          <TextForm titulo="CEDULA" texto={`${nacionalidad}-${cedula}`} />
        </Grid>
        <Grid mt={2} item xs={12} sm={6}>
          <TextForm titulo="CARGO" texto={`${descripcion_cargo}`} />
        </Grid>
        <Grid mt={2} item xs={12} sm={6}>
          <TextForm titulo="INGRESO MENSUAL" texto={`Bs. ${salario}`} />
        </Grid>
        <Grid mt={2} item xs={12} sm={6}>
          <TextForm titulo="DEPENDENCIA" texto={`${nombre}`} />
        </Grid>
        <Grid mt={2} item xs={12}>
          <p>
            Los datos que se muestran aqui deben ser los mismos que estan en la
            constacia de trabajo
          </p>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default memo(Valido);
