import React  from 'react';
import DatosTrabajador from './components/DatosTrabajador';
import { PERMISSIONS } from 'utils/enums';
/*MUI  */
import { Typography } from '@mui/material';

/* Hooks */
import { useAppSelector } from 'hooks/useRedux';
import { Helmet } from 'react-helmet-async';
import { getPermiso } from 'utils/helpers';
/* interfaces */
import IPage from 'interfaces/page';




const Dash: React.FC<IPage> = () => {
  const { user } = useAppSelector((state) => state.authReducer);
  const { email } = user;
  const { userData, isLoading, isGenerating } = useAppSelector(
    (state) => state.userReducer
  );
  const { menu } = useAppSelector((state) => state.authReducer);

   const {permissions} = menu as unknown as IPage 


  return (
    <>
      <Helmet>
        <title>SAMINCYT - Inicio</title>
      </Helmet>
      <section>
        <Typography
          className="page-title" component='h1'>
          DATOS DEL FUNCIONARIO
        </Typography>
        {userData.fecha_ingreso === null ? (
          <Typography
          className="page-title"
            component='h2'
            fontSize={16}>
            No tiene datos de Nómina comuníquese con RRHH
          </Typography>
        ) : (
          <DatosTrabajador
            datos={userData}
            loading={isLoading}
            generating={isGenerating}
            correo={email}
            showButton={getPermiso(
              permissions,
              PERMISSIONS.DISPLAY_BUTTON_CONSTANCY,
            
            )}
            disabledButton={getPermiso(
              permissions,
              PERMISSIONS.DISABLED_BUTTON_CONSTANCY,
           
            )}
          />
        )}
      </section>
    </>
  );
};

export default Dash;
