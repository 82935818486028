/* MUI */
import { Button, CircularProgress, Stack } from '@mui/material';
import FormDatePicker from 'components/presentationals/Fields/FormDatePicker';
/* hooks */
import { useFormHook } from 'hooks/useFormHook';
/* interfaces */
import { IPROPS } from 'interfaces/index';
import { SubmitHandler } from 'react-hook-form';

const FormArc: React.FC<TFormArcProps> = ({
  onSubmit,
  defaultValues,
  isLoading,
  disabledButton
}) => {
  const { control, handleSubmit, getValues } = useFormHook(defaultValues);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormDatePicker
          name="anio"
          label="Seleccione año"
          value={getValues('date')}
          control={control}
        />

        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{ marginTop: 4 }}
          disabled={disabledButton ?? isLoading}>
          {isLoading ? <CircularProgress size={24} /> : ' Generar ARC'}
        </Button>
      </Stack>
    </form>
  );
};

export type TFormArcProps = {
  onSubmit: SubmitHandler<IPROPS>;
  defaultValues: IPROPS;
  isLoading: boolean;
  disabledButton?: boolean;
};
export default FormArc;
